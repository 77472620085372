import { createFileRoute } from '@tanstack/react-router';
import React, { useMemo } from 'react';
import { type InferType, object, string } from 'yup';

import { createLayout } from '@/components/page/layout/createLayout';
import { BookingNavigation } from '@/components/page/navigation/booking-navigation';
import { RegistrationLeafletTile, RegistrationTile } from '@/features/auth';

const SEARCH_PARAMS = object({
    token: string(),
});

export const Registration: React.FC = () => {
    const { Layout } = useMemo(
        () =>
            createLayout({
                tiles: {
                    account_tile: <RegistrationLeafletTile />,
                    registration_tile: <RegistrationTile />,
                },
                base: [
                    { name: 'account_tile', md: 4 },
                    { name: 'registration_tile', md: 8, mobileBase: true },
                ],
            }),
        [],
    );
    return <Layout useNavigation={BookingNavigation} />;
};

export const Route = createFileRoute('/registration/')({
    validateSearch(search): InferType<typeof SEARCH_PARAMS> {
        return SEARCH_PARAMS.cast(search);
    },
    component: Registration,
});

export { Route as RegistrationRoute };
