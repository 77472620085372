import { queryOptions, useQuery } from '@tanstack/react-query';

import callApi from '@/api';
import { PreloadedCustomerDetails } from '@/entity/account/customer/PreloadedCustomerDetails';

export async function preloadAccountInformation(token?: string) {
    if (!token) return null;
    const { data } = await callApi(`public/preload-account-information-referral?token=${token}`, {
        method: 'GET',
        data: token,
    });
    return new PreloadedCustomerDetails(data);
}

export const preloadAccountInformationQueryOptions = (token?: string) =>
    queryOptions({
        queryKey: ['preload-account-information', token],
        queryFn: async () => preloadAccountInformation(token),
        refetchOnWindowFocus: false,
    });

export const usePreloadAccountInformation = (token?: string) => useQuery(preloadAccountInformationQueryOptions(token));
