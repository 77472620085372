import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Autocomplete, Box, createFilterOptions, Grid2, TextField, Typography } from '@mui/material';
import omit from 'lodash/omit';
import uniqueId from 'lodash/uniqueId';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useAllAssociations } from '@/api/public';
import { ErrorMessage } from '@/components/form/error-message';
import { type FormFieldProps } from '@/components/form/mod';
import { Association } from '@/entity/association/Association';

/**
 *  Change the default filter behavior to limit the suggested options to 50 with {@link createFilterOptions}
 */
const filterOptions = createFilterOptions<Association>({
    stringify: association => association.associationName + association.contactData.fullAddress,
    limit: 50,
    ignoreCase: true,
    trim: true,
});

interface AssociationAutocompleteProps extends FormFieldProps {
    readonly onChangeAddress?: (address: string) => void;
    readonly onBlurField?: (associationName: string) => void;
}

export const AssociationAutocomplete: React.FC<AssociationAutocompleteProps> = ({
    name,
    label,
    helperText,
    onChangeAddress,
    onBlurField,
}) => {
    const { control } = useFormContext();
    const { data: associations } = useAllAssociations();

    const handleBlur = (associationName: string) => onBlurField?.(associationName);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                return (
                    <Autocomplete
                        freeSolo
                        filterSelectedOptions
                        value={value}
                        options={associations ?? []}
                        filterOptions={filterOptions}
                        isOptionEqualToValue={(option, v) => option.id === v.id}
                        getOptionLabel={association => getAssociationName(association)}
                        renderInput={params => (
                            <TextField
                                required
                                name={name}
                                error={Boolean(error)}
                                helperText={error ? <ErrorMessage error={error} /> : helperText}
                                {...params}
                                label={label}
                            />
                        )}
                        renderOption={renderAutocompleteResults}
                        onChange={(_e, item) => {
                            onChange(getAssociationName(item));
                            if (onChangeAddress && item instanceof Association)
                                onChangeAddress(`${item.contactData.postCode} ${item.contactData.street}`);
                        }}
                        onInputChange={(_e, newInputValue) => onChange(newInputValue)}
                        onBlur={() => {
                            handleBlur(value);
                            onBlur();
                        }}
                    />
                );
            }}
        />
    );
};

const getAssociationName = (association: Association | string | null) => {
    return typeof association === 'string' ? association : association?.associationName || '';
};

function renderAutocompleteResults(props: React.HTMLAttributes<HTMLLIElement>, association: Association) {
    return (
        <li key={uniqueId('school_prediction')} {...omit(props, 'key')}>
            <Grid2 container alignItems="center">
                <Grid2>
                    <Box component={AccountBalanceIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                </Grid2>
                <Grid2 size="grow">
                    <span>{association?.associationName}</span>
                    <Typography variant="body2" color="text.secondary">
                        {association?.contactData.fullAddress}
                    </Typography>
                </Grid2>
            </Grid2>
        </li>
    );
}
