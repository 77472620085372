import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import { Avatar, type AvatarProps, styled } from '@mui/material';
import { type LinkOptions } from '@tanstack/react-router';
import { useCallback } from 'react';

import { type NavigationItem } from '@/components/page/navigation/useNavigation';
import { useAppProgress } from '@/core/app-progress/mod';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';

export const NavAvatar = ({ navigate }: NavigationItem) => {
    const progressProps = useDetermineStepByNavigate(navigate.to);

    return (
        <ProgressAvatar {...progressProps}>
            <ProgressIcon {...progressProps} />
        </ProgressAvatar>
    );
};

type PathOption = LinkOptions['to'];

const useDetermineStepByNavigate = (to?: PathOption) => {
    const { currentStep, progress } = useAppProgress();
    const isBookingConfirmation = currentStep === BookingStep.BOOKING_CONFIRMATION;

    const findStepIndexByNavigate = useCallback(
        (to?: PathOption) => {
            const idx = progress.steps.findIndex(s => s.navigate.to === to);
            if (idx === -1) throw new Error(`Step not found with ${to} navigate option`);
            return idx;
        },
        [progress.steps],
    );

    const isPreviousStepByNavigate = useCallback(
        (to?: PathOption) => {
            const idx = findStepIndexByNavigate(to);
            const currentIdx = progress.steps.findIndex(s => s.name === currentStep);
            return idx < currentIdx;
        },
        [currentStep, findStepIndexByNavigate, progress.steps],
    );

    const isCurrentStepByNavigate = useCallback(
        (to?: PathOption) => {
            const idx = findStepIndexByNavigate(to);
            return progress.steps[idx].name === currentStep;
        },
        [currentStep, findStepIndexByNavigate, progress.steps],
    );

    return {
        isPrevious: isBookingConfirmation || isPreviousStepByNavigate(to),
        isCurrent: isCurrentStepByNavigate(to),
    };
};

interface ProgressProps {
    readonly isPrevious: boolean;
    readonly isCurrent: boolean;
}

interface NavAvatarProps extends AvatarProps, ProgressProps {}

const ProgressAvatar = styled(({ isCurrent, isPrevious, ...props }: NavAvatarProps) => <Avatar {...props} />)(
    ({ isPrevious, theme }) => ({
        width: 22,
        height: 22,
        '.MuiSvgIcon-root': {
            fontSize: isPrevious ? 14 : 10,
        },
        backgroundColor: isPrevious ? theme.vars.palette.primary.main : theme.vars.palette.common.white,
        border: `1px solid ${theme.vars.palette.primary.main}`,
        color: isPrevious ? theme.vars.palette.primary.contrastText : theme.vars.palette.primary.main,
    }),
);

const ProgressIcon = ({ isCurrent, isPrevious }: ProgressProps) => {
    if (isPrevious) return <CheckIcon fontSize="small" />;
    if (isCurrent) return <CircleIcon fontSize="small" />;
    return '';
};
