import { yupResolver } from '@hookform/resolvers/yup';
import { Grid2, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { preloadAccountInformationQueryOptions } from '../api/preload-account-information-referral';
import { useRegistration } from '../api/registration';

import { duplicateCustomerEmailValidation } from '@/api/user/endpoints';
import { ActionButton } from '@/components/elements/action/action-button';
import { Heading } from '@/components/elements/heading';
import { InteractiveText } from '@/components/elements/text/interactive-text';
import { CheckField } from '@/components/form/fields/check-field';
import { TextField } from '@/components/form/fields/text-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';
import { AuthInputs } from '@/components/form-inputs/auth-inputs';
import { ContactDataInputs } from '@/components/form-inputs/contact-data-inputs';
import { OrganizationInputs } from '@/components/form-inputs/organization-inputs';
import { trackRegisteredCustomerGroup } from '@/core/google/utils/ga-utils';
import { useSnack } from '@/core/snack/mod';
import { registrationSchema, type IRegistrationFormValues } from '@/features/auth';
import { Dialog } from '@/features/ui';
import { useDisclosure } from '@/hooks/useDisclosure';
import { RegistrationRoute } from '@/main/routes/registration';
import { getPreloadedRegistrationFormValues } from '@/utils/account';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

export const RegistrationForm: React.FC = () => {
    const { t } = useTranslation();
    const register = useRegistration();
    const navigate = useNavigate();
    const snack = useSnack();
    const queryClient = useQueryClient();

    const { token } = RegistrationRoute.useSearch();

    const formMethods = useForm<IRegistrationFormValues>({
        async defaultValues() {
            try {
                const data = await queryClient.ensureQueryData(preloadAccountInformationQueryOptions(token));
                return getPreloadedRegistrationFormValues(data, token);
            } catch {
                return getPreloadedRegistrationFormValues(null, token);
            }
        },
        resolver: yupResolver(registrationSchema),
        mode: 'onBlur',
        shouldUnregister: true,
    });

    const onSubmit = async (values: IRegistrationFormValues) => {
        const email = formMethods.getValues('email');

        try {
            await duplicateCustomerEmailValidation(email);
        } catch {
            formMethods.setError(
                'email',
                {
                    type: 'duplicate-email-validation',
                    message: t('validations:email.duplicate_email_customer'),
                },
                { shouldFocus: true },
            );
            return;
        }

        try {
            const { id } = await register.mutateAsync(values);

            if (values.customerGroupName) trackRegisteredCustomerGroup(values.customerGroupName);
            if (id != null) await navigate({ to: '/registration/confirmation', search: { id: id.toString() } });
        } catch {
            snack.push(t('validations:general.error'), 'error');
        }
    };

    const agbDialog = useDisclosure();
    return (
        <>
            {token && (
                <>
                    <Heading
                        disableGutterBottom
                        color="primary"
                        title={t('account.referral.info_headline_registration')}
                    />
                    <SanitizedInnerHTML html={t('account.referral.info_text_registration')} />
                    <Typography variant="caption">{t('account.referral.valid_until')}</Typography>
                </>
            )}
            <Grid2
                container
                sx={{ marginTop: token ? 5 : 0 }}
                spacing={0}
                size={{
                    xs: 12,
                    sm: 12,
                }}>
                <FormProvider {...formMethods}>
                    <form noValidate onSubmit={formMethods.handleSubmit(onSubmit)}>
                        <AuthInputs />

                        <OrganizationInputs withOrganizationRegistration />

                        <ContactDataInputs withoutEmail />

                        <FormFieldRow>
                            <CheckField
                                label={
                                    <Typography variant="body2">
                                        {t('account.core_data.subscribe_to_newsletter')}
                                    </Typography>
                                }
                                name="subscribedToNewsletter"
                            />

                            <CheckField
                                label={
                                    <Typography variant="body2">
                                        <Trans
                                            i18nKey="registration.agb"
                                            components={{
                                                button1: <InteractiveText color="primary" onClick={agbDialog.open} />,
                                            }}
                                        />
                                    </Typography>
                                }
                                name="agb"
                            />
                        </FormFieldRow>
                        <TextField name="referralToken" type="hidden" />
                        <FormFieldRow>
                            <ActionButton type="submit">{t('account.register')}</ActionButton>
                        </FormFieldRow>
                    </form>
                </FormProvider>
            </Grid2>
            <Dialog
                {...agbDialog}
                variant="info"
                maxWidth="lg"
                title={t('agb.modal_title_busfinder')}
                description={
                    <SanitizedInnerHTML html={t('page_agb_content', { interpolation: { escapeValue: false } })} />
                }
            />
        </>
    );
};
