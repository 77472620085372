import { TranslatedSvg } from '@/assets/translated-svg';
import { useLayout } from '@/components/page/layout/mod';

export const CO2Stamp = () => {
    const { isLargeDesktop, isSmallMobile } = useLayout();

    let size = 60;
    if (!isLargeDesktop && !isSmallMobile) size = 45;

    return (
        <TranslatedSvg
            path="icons/co2/hvo100"
            sx={{
                position: 'absolute',
                top: -size / 3,
                left: -size / 3,
                width: size,
                height: size,
                zIndex: 2,
                '& circle': {
                    fill(theme) {
                        return theme.vars.palette.eco.main;
                    },
                },
            }}
        />
    );
};
