import IntermediateIcon from '@mui/icons-material/LocationOnOutlined';
import StartIcon from '@mui/icons-material/PersonPinCircleOutlined';
import EndIcon from '@mui/icons-material/PinDropOutlined';
import { type FC } from 'react';

import { Pin } from './pin';

import { type IPosition } from '@/features/journey-planning';

interface RoutePinProps {
    readonly variant?: 'start' | 'intermediate' | 'end';
    readonly position?: IPosition;
}

export const RoutePin: FC<RoutePinProps> = ({ variant, position }) => {
    if (!variant && position) variant = position.first ? 'start' : position.last ? 'end' : 'intermediate';

    return (
        <Pin>
            {variant === 'start' && <StartIcon fontSize="inherit" htmlColor="inherit" />}
            {variant === 'intermediate' && <IntermediateIcon fontSize="inherit" htmlColor="inherit" />}
            {variant === 'end' && <EndIcon fontSize="inherit" htmlColor="inherit" />}
        </Pin>
    );
};
