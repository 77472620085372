import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from '@lexical/list';
import { $createHeadingNode } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import BulletedListIcon from '@mui/icons-material/FormatListBulletedOutlined';
import NumberedListIcon from '@mui/icons-material/FormatListNumberedOutlined';
import ParagraphIcon from '@mui/icons-material/FormatTextdirectionLToROutlined';
import H3Icon from '@mui/icons-material/Looks3Outlined';
import H1Icon from '@mui/icons-material/LooksOneOutlined';
import H2Icon from '@mui/icons-material/LooksTwoOutlined';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { $createParagraphNode, $getSelection, $isRangeSelection, type LexicalEditor } from 'lexical';
import { type FC, type MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type BlockType } from '../../types';

import { ToolbarButton } from './toolbar-button';

type BlockTypeItem = {
    icon: JSX.Element;
    type: BlockType;
};

const items: BlockTypeItem[] = [
    {
        icon: <ParagraphIcon />,
        type: 'paragraph',
    },
    {
        icon: <H1Icon />,
        type: 'h1',
    },
    {
        icon: <H2Icon />,
        type: 'h2',
    },
    {
        icon: <H3Icon />,
        type: 'h3',
    },
    {
        icon: <BulletedListIcon />,
        type: 'bullet',
    },
    {
        icon: <NumberedListIcon />,
        type: 'number',
    },
];

const defaultItem = items.find(item => item.type === 'paragraph')!;

interface BlockFormatDrowdownProps {
    readonly editor: LexicalEditor;
    readonly blockType: BlockType;
    readonly disabled: boolean;
}

export const BlockFormatDropdown: FC<BlockFormatDrowdownProps> = ({ editor, blockType, disabled }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleClick = (type: BlockType) => () => {
        switch (type) {
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6': {
                editor.update(() => {
                    const selection = $getSelection();
                    $setBlocksType(selection, () => $createHeadingNode(type));
                });
                break;
            }
            case 'bullet': {
                editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
                break;
            }
            case 'number': {
                editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
                break;
            }
            default: {
                editor.update(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        $setBlocksType(selection, () => $createParagraphNode());
                    }
                });
                break;
            }
        }
        handleClose();
    };

    const selectedItem = useMemo(() => items.find(item => item.type === blockType) ?? defaultItem, [blockType]);

    return (
        <>
            <ToolbarButton
                disabled={disabled}
                // aria-controls="into-menu"
                // aria-haspopup="listbox"
                // aria-expanded={anchorEl ? 'true' : undefined}
                label={t(`richtexteditor.format.block.${selectedItem.type}.label`)}
                icon={selectedItem.icon}
                onClick={handleOpen}
            />
            <Menu id="into-menu" anchorEl={anchorEl} open={Boolean(anchorEl)}>
                {items.map(item => (
                    <MenuItem key={item.type} selected={item.type === blockType} onClick={handleClick(item.type)}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText>{t(`richtexteditor.format.block.${item.type}.label`)}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
