import { ListItemIcon, ListItemText, MenuItem, styled } from '@mui/material';
import { type FC, type MouseEvent } from 'react';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: theme.spacing(1),
}));

interface ToolbarButtonProps {
    readonly selected?: boolean;
    readonly label?: string;
    readonly hideLabel?: boolean;
    readonly icon?: JSX.Element;
    readonly disabled?: boolean;
    readonly onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export const ToolbarButton: FC<ToolbarButtonProps> = ({ selected, label, hideLabel, icon, disabled, onClick }) => {
    return (
        <StyledMenuItem
            selected={selected}
            aria-label={label}
            disabled={disabled}
            sx={{
                '& .MuiListItemIcon-root': {
                    minWidth: label && !hideLabel ? undefined : 0,
                },
            }}
            onClick={onClick}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            {label && !hideLabel && <ListItemText>{label}</ListItemText>}
        </StyledMenuItem>
    );
};
