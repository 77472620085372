import {
    createTheme,
    responsiveFontSizes,
    type Breakpoint,
    type ColorSystem,
    type Palette,
    type PaletteColor,
    type Theme,
    type ThemeOptions,
} from '@mui/material/styles';
import type {} from '@mui/material/themeCssVarsAugmentation';
import { createBreakpoints, type Breakpoints } from '@mui/system';
import { deepmerge } from '@mui/utils';

import { type ThemeSettings } from '../app-ctx/AppSettings';

import { type ThemeOverrides } from '@/core/theme/ThemeOverrides';
import { CLASS_TOUR_HIGHLIGHT } from '@/core/tour/mod';

type CreateThemeOptions = NonNullable<Parameters<typeof createTheme>[0]>;

type PaletteColorKeys = {
    [K in keyof Palette]: Palette[K] extends PaletteColor ? K : never;
}[keyof Palette];

const BASE_THEME: CreateThemeOptions = {
    // Color Schemes
    colorSchemes: {
        dark: true,
        light: {
            palette: {
                contrastThreshold: 3,
                tonalOffset: 0.1,
                common: {
                    black: '#000000',
                    white: '#ffffff',
                },
                primary: {
                    main: '#0d66aa',
                },
                secondary: {
                    main: '#feda13',
                },
                error: {
                    main: '#ff5c62',
                },
                warning: {
                    main: '#cb8f17',
                    // light: '#feda13',
                    contrastText: '#ffffff',
                },
                info: {
                    main: '#0d66aa',
                },
                success: {
                    main: '#08ac4c',
                    contrastText: '#ffffff',
                    // light: '#8cc443',
                },
                eco: {
                    main: '#b7cb4a',
                },
                grey: {
                    '50': '#fafafa',
                    '100': '#f6f6f6',
                    '200': '#ededed',
                    '300': '#e8e8e8',
                    '400': '#cccccc',
                    '500': '#9e9e9e',
                    '600': '#6B6B6B',
                    '700': '#5c5c5c',
                    '800': '#333333',
                    '900': '#2e2e2e',
                    A100: '#d5d5d5',
                    A200: '#BDBDBD',
                    A400: '#303030',
                    A700: '#616161',
                },
                background: {
                    default: '#ffffff',
                    disabled: '#d5d5d5',
                },
                text: {
                    primary: '#6B6B6B',
                    disabled: '#9E9E9E',
                },
                action: {
                    disabledOpacity: 0.5,
                },
                FilledInput: {
                    bg: '#fff',
                    hoverBg: '#fff',
                    disabledBg: 'var(--mui-palette-background-disabled)',
                    border: 'var(--mui-palette-background-disabled)',
                    hoverBorder: 'var(--mui-palette-primary-main)',
                    disabledBorder: 'var(--mui-palette-background-disabled)',
                },
                AppBar: {
                    defaultBg: 'var(--mui-palette-background-default)',
                    defaultColor: 'var(--mui-palette-primary-main)',
                },
                Alert: {
                    successStandardBg: 'color-mix(in srgb, var(--mui-palette-success-light),#fff 90%)',
                    successColor: 'color-mix(in srgb, var(--mui-palette-success-dark),#000 25%)',
                    successIconColor: 'var(--mui-palette-success-light)',

                    infoStandardBg: 'color-mix(in srgb, var(--mui-palette-info-light),#fff 90%)',
                    infoColor: 'color-mix(in srgb, var(--mui-palette-info-dark),#000 25%)',
                    infoIconColor: 'var(--mui-palette-info-light)',

                    warningStandardBg: 'color-mix(in srgb, var(--mui-palette-warning-light),#fff 90%)',
                    warningColor: 'color-mix(in srgb, var(--mui-palette-warning-dark),#000 25%)',
                    warningIconColor: 'var(--mui-palette-warning-light)',

                    errorStandardBg: 'color-mix(in srgb, var(--mui-palette-error-light),#fff 90%)',
                    errorColor: 'color-mix(in srgb, var(--mui-palette-error-dark),#000 25%)',
                    errorIconColor: 'var(--mui-palette-error-light)',
                },
                // TODO: TilePart shadows bacame unused. Remove them at some point.
                TilePart: {
                    attachedTopShadow: '0 4px 8px -3px rgba(0,0,0,.3)',
                    attachedBottomShadow: '0 -4px 8px -3px rgba(0,0,0,.3)',
                },
                MapPin: {
                    bg: 'var(--mui-palette-primary-main)',
                    color: 'var(--mui-palette-primary-contrastText)',
                    border: 'var(--mui-palette-primary-contrastText)',
                },
            },
            opacity: {
                disabled: 0.3,
            },
        },
    },
    // Breakpoints
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            small_mobile: 400,
        },
    },
    // Shape
    shape: {
        borderRadius: 4,
    },
    // Typography
    typography: {
        fontFamily: 'Red Hat Text Variable, Red Hat Text Variable, Open Sans, Helvetica, Arial, sans-serif',
        fontSize: 14,
        fontWeightBold: 700,
        fontWeightMedium: 500,
        fontWeightRegular: 400,
        h3: {
            fontWeight: 700,
            fontSize: '1.35rem',
            lineHeight: 1.6,
        },
        h3Sub: {
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: 1.6,
        },
        h4: {
            fontWeight: 700,
            fontSize: '1.25rem',
            lineHeight: 1.6,
        },
        h4Sub: {
            fontWeight: 500,
            fontSize: '0.94rem',
            lineHeight: 1.6,
        },
        h5: {
            fontWeight: 700,
            fontSize: '1.15rem',
            lineHeight: 1.6,
        },
        h5Sub: {
            fontWeight: 500,
            fontSize: '0.8rem',
            lineHeight: 1.6,
        },
        h6: {
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: 1.6,
        },
        price: {
            fontWeight: 500,
            fontSize: '1.15rem',
            lineHeight: 1.6,
        },
        body1: {
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.6,
        },
        body2: {
            fontWeight: 400,
            fontSize: '0.9rem',
            lineHeight: 1.6,
        },
        body3: {
            fontWeight: 400,
            fontSize: '0.8rem',
            lineHeight: 1.6,
        },
        body4: {
            fontWeight: 400,
            fontSize: '0.7rem',
            lineHeight: 1.6,
        },
        code: {
            fontWeight: 400,
            fontFamily: 'Red Hat Mono Variable, Monospace',
            fontSize: '1rem',
            lineHeight: 1.6,
        },
    },
};

interface ThemeProps {
    breakpointScalingFactor?: number;
    portalContainer?: HTMLElement;
    isPrint?: boolean;
    isDesktop?: boolean;
}

export class AppTheme {
    static breakPointWidth(key: Breakpoint, min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY): string {
        let value = BASE_THEME.breakpoints?.values?.[key] ?? 0;
        value = Math.max(value, min);
        value = Math.min(value, max);
        return `${value}px`;
    }

    private readonly themeProps: ThemeProps;

    private readonly overrides: ThemeOverrides | undefined;

    constructor(overrides?: ThemeOverrides, props: ThemeProps = {}) {
        this.overrides = overrides;
        this.themeProps = props;
    }

    public create(settings: ThemeSettings, isEmbedding: boolean): Theme {
        // Stage 1: Create the base theme
        let theme = createTheme(deepmerge(BASE_THEME, this.overrides?.themeOptions));

        // Stage 2: Apply custom color augmentations and scaled breakpoints
        const customColorAugmentation: PaletteColorKeys[] = ['eco'];

        theme = createTheme(theme, {
            palette: Object.fromEntries(
                customColorAugmentation.map(name => [
                    name,
                    theme.palette.augmentColor({ color: theme.palette[name], name }),
                ]),
            ),
            breakpoints: this.makeScaledBreakpointsPartial(theme),
        });

        // Extract the prepared ColorScheme for google maps theme
        const cs = theme.colorSchemes[settings.forceColorScheme ?? 'light'];
        if (!cs) {
            throw new Error('Color scheme not found');
        }

        // Stage 3: Override mixins, components and add googleMaps and cssVariable settings
        theme = createTheme({
            ...theme,
            cssVariables: {
                colorSchemeSelector: 'class',
                rootSelector: isEmbedding ? ':host' : ':root',
            },
            components: this.makeComponentOptions(),
            googleMaps: this.overrides?.googleMaps ?? this.makeGoogleMapsOptions(cs),
            mixins: this.makeMixinsOptions(theme.breakpoints),
        });

        // Stage 4: Enable responsive font sizes
        theme = responsiveFontSizes(theme);

        return theme;
    }

    private makeScaledBreakpointsPartial(options: Theme): Breakpoints {
        // Crate the breakpoints based on given config
        const breakpoints: Breakpoints = createBreakpoints(options.breakpoints ?? {});

        const scalingFactor = this.themeProps.breakpointScalingFactor ?? 1;

        // If scalingFactor is equal to 1 we are done.
        if (scalingFactor === 1) return breakpoints;

        // Else scale all breakpoint values
        breakpoints.keys.forEach(key => {
            breakpoints.values[key] /= scalingFactor;
        });

        // Use the modified breakpoints object. Copy over keys and values for a new config
        return createBreakpoints({ keys: [...breakpoints.keys], values: { ...breakpoints.values } });
    }

    private makeMixinsOptions(breakpoints: Breakpoints): CreateThemeOptions['mixins'] {
        return {
            toolbar: {
                minHeight: 56,
                [breakpoints.up('md')]: {
                    minHeight: 48,
                },
            },
        };
    }

    private makeComponentOptions(): ThemeOptions['components'] {
        const portalContainer = this.themeProps.portalContainer;

        const sharedRootOverrides = (theme: Omit<Theme, 'components'>) => ({
            '@page': {
                margin: '2cm',
            },
            // Otherwise the body background is white and it covers up the page numbers in the print header
            '@media print': {
                body: {
                    backgroundColor: 'transparent',
                },
            },
            address: {
                fontStyle: 'inherit',
            },

            [`.${CLASS_TOUR_HIGHLIGHT}`]: {
                boxShadow: `0 0 5px 5px ${theme.vars.palette.primary.light}`,
            },
        });

        return {
            MuiScopedCssBaseline: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        height: '100%',
                        '& .MuiInputBase-root *': {
                            // ScopedCssBaseline sets box-sizing: 'inherit' for child components,
                            // overriding CSS classes generated by MUI.
                            // https://github.com/mui/material-ui/issues/20461
                            boxSizing: 'content-box',
                        },
                        ...sharedRootOverrides(theme),
                    }),
                },
            },
            MuiCssBaseline: {
                styleOverrides: theme => ({
                    ...sharedRootOverrides(theme),
                }),
            },
            MuiAppBar: {
                variants: [
                    {
                        props: { color: 'default' },
                        style: ({ theme }) => ({
                            color: theme.vars.palette.AppBar.defaultColor,
                        }),
                    },
                ],
                styleOverrides: {
                    root: ({ theme }) => ({
                        zIndex: theme.vars.zIndex.appBar,
                    }),
                },
            },
            MuiAlert: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        [theme.breakpoints.down('sm')]: {
                            flexWrap: 'wrap',
                        },
                    }),
                    message: ({ theme }) => ({
                        [theme.breakpoints.down('sm')]: {
                            flex: 1,
                        },
                    }),
                },
            },
            MuiLink: {
                defaultProps: {
                    underline: 'hover',
                },
            },
            MuiButton: {
                defaultProps: { size: this.themeProps.isDesktop ? 'small' : 'medium' },
                styleOverrides: {
                    root: {
                        fontWeight: 700,
                        textTransform: 'none',
                    },
                },
                variants: [
                    {
                        props: {
                            variant: 'cta',
                            // color: 'primary',
                        },
                        style: ({ theme }) => ({
                            '&:after': {
                                content: '"NEU"',
                                display: 'inline-block',
                                marginLeft: '0.2rem',
                                marginBottom: '0.5em',
                                fontSize: '0.9em',
                                verticalAlign: 'super',
                                color: theme.vars.palette.secondary.main, // Access theme color
                            },
                        }),
                    },
                ],
            },
            MuiCard: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        boxShadow: theme.vars.shadows[3],
                    }),
                },
            },
            MuiRating: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        '& .MuiSvgIcon-root': {
                            fontSize: 'inherit',
                        },
                    },
                    sizeSmall: ({ theme }) => ({
                        fontSize: theme.spacing(1.8),
                    }),
                    icon: ({ theme }) => ({
                        color: theme.vars.palette.primary.main,
                    }),
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        '.MuiTab-root': {
                            fontSize: 'inherit',
                        },
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: ({ theme }) => ({
                        maxWidth: 600,
                        padding: theme.spacing(1),
                        fontSize: theme.typography.body3.fontSize,
                    }),
                    popper: ({ theme }) => ({
                        zIndex: theme.zIndex.modal - 1,
                    }),
                },
                defaultProps: {
                    enterTouchDelay: 0,
                    leaveTouchDelay: 3000,
                    slotProps: {
                        popper: {
                            modifiers: [
                                {
                                    name: 'flip',
                                    options: {
                                        fallbackPlacements: ['top', 'bottom', 'left', 'right'],
                                    },
                                },
                            ],
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        [theme.breakpoints.down('sm')]: {
                            fontSize: theme.typography.body2.fontSize,
                        },
                    }),
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'filled',
                },
            },
            MuiFormControl: {
                defaultProps: {
                    variant: 'filled',
                },
                styleOverrides: {
                    root: {
                        '&:has(input[type="hidden"])': {
                            position: 'absolute',
                            '.MuiInputBase-root': {
                                borderWidth: 0,
                            },
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: theme.vars.palette.FilledInput.border,
                        borderRadius: theme.vars.shape.borderRadius,
                        '&:hover, &.Mui-focused': {
                            borderColor: theme.vars.palette.FilledInput.hoverBorder,
                        },
                        '&.Mui-disabled': {
                            borderColor: theme.vars.palette.FilledInput.disabledBorder,
                        },
                    }),
                },
                defaultProps: {
                    disableUnderline: true,
                },
            },
            MuiUseMediaQuery: {
                defaultProps: {
                    noSsr: true,
                },
            },
            MuiPopover: {
                defaultProps: {
                    container: portalContainer,
                },
            },
            MuiPopper: {
                defaultProps: {
                    container: portalContainer,
                },
            },
            MuiModal: {
                defaultProps: {
                    container: portalContainer,
                },
                styleOverrides: {
                    root: {
                        '& .MuiDialog-paperWidthXs': {
                            maxWidth: AppTheme.breakPointWidth('xs', 444),
                        },
                        '& .MuiDialog-paperWidthSm': {
                            maxWidth: AppTheme.breakPointWidth('sm'),
                        },
                        '& .MuiDialog-paperWidthMd': {
                            maxWidth: AppTheme.breakPointWidth('md'),
                        },
                        '& .MuiDialog-paperWidthLg': {
                            maxWidth: AppTheme.breakPointWidth('lg'),
                        },
                        '& .MuiDialog-paperWidthXl': {
                            maxWidth: AppTheme.breakPointWidth('xl'),
                        },
                    },
                },
            },
            MuiSnackbar: {
                styleOverrides: {
                    anchorOriginTopLeft: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 24px)',
                        },
                        top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 12px)',
                    }),
                    anchorOriginTopCenter: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 24px)',
                        },
                        top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 12px)',
                    }),
                    anchorOriginTopRight: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 24px)',
                        },
                        top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 12px)',
                    }),
                    anchorOriginBottomCenter: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 24px)',
                        },
                        bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 12px)',
                    }),
                    anchorOriginBottomLeft: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 24px)',
                        },
                        bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 12px)',
                    }),
                    anchorOriginBottomRight: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 24px)',
                        },
                        bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 12px)',
                    }),
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        top: 'var(--bf-env-boundary-offset-top, 0)',
                        bottom: 'var(--bf-env-boundary-offset-bottom, 0)',
                        height: 'unset',
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        top: 'var(--bf-env-boundary-offset-top, 0)',
                        bottom: 'var(--bf-env-boundary-offset-bottom, 0)',
                    },
                },
            },
        };
    }

    private makeGoogleMapsOptions(colorSystem: ColorSystem): google.maps.MapTypeStyle[] {
        // We use the color values directly instead of using CSS Variables.
        // Google maps is not capable of using CSS Variables.
        const { palette } = colorSystem;

        return [
            {
                elementType: 'geometry',
                stylers: [{ color: palette.grey[100] }],
            },
            {
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
            },
            {
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey.A700 }],
            },
            {
                elementType: 'labels.text.stroke',
                stylers: [{ color: palette.grey[100] }],
            },
            {
                featureType: 'administrative.land_parcel',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey.A200 }],
            },
            {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [{ color: palette.grey[200] }],
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey[600] }],
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [{ color: palette.grey[300] }],
            },
            {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey[500] }],
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ color: palette.common.white }],
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey[600] }],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{ color: palette.grey.A100 }],
            },
            {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey.A700 }],
            },
            {
                featureType: 'road.local',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey[500] }],
            },
            {
                featureType: 'transit.line',
                elementType: 'geometry',
                stylers: [{ color: palette.grey[300] }],
            },
            {
                featureType: 'transit.station',
                elementType: 'geometry',
                stylers: [{ color: palette.grey[200] }],
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{ color: palette.grey[400] }],
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey[500] }],
            },
        ];
    }
}
