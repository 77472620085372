import { useTranslation } from 'react-i18next';

import { TextField } from '@/components/form/fields/text-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';

export const BusinessInputs = () => {
    const { t } = useTranslation();

    return (
        <FormFieldRow>
            <TextField name="organizationPoNumber" label={t('account.organization_data.po_number')} />
            <TextField name="organizationCostCenter" label={t('account.organization_data.cost_center')} />
        </FormFieldRow>
    );
};
