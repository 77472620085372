import { DateTime } from 'luxon';

import { defaultJourneyFormValues } from '../utils/default-values';

import { useAppCtx } from '@/core/app-ctx/mod';
import { Location } from '@/entity/basic/Location';
import { HomePageRoute } from '@/main/routes';

export function useDefaultJourneyFormValues() {
    const { fromAddress, fromLat, fromLng, pax, departureDateTime } = HomePageRoute.useSearch();
    const { isIntegration, integration } = useAppCtx();

    const location =
        fromAddress != null && fromLat != null && fromLng != null
            ? new Location(fromAddress, fromLat, fromLng)
            : undefined;

    const defaultBookingLeadTime =
        isIntegration && integration?.singleCompany && integration?.bookingLeadTime
            ? DateTime.now().plus({ hours: integration.bookingLeadTime })
            : undefined;

    const dateTime = departureDateTime ? DateTime.fromISO(departureDateTime) : defaultBookingLeadTime;

    return defaultJourneyFormValues(pax ?? undefined, {
        location,
        departureDateTime: dateTime,
    });
}
