import { Checkbox, FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import has from 'lodash/has';
import React from 'react';
import { useController } from 'react-hook-form';

import { ErrorMessage } from '@/components/form/error-message';
import { makeFieldIds, type FormFieldProps } from '@/components/form/mod';

/*
 * The ways to display the error to the user:
 * COLOR: the label is colored red (default)
 * TEXT: the error is displayed below the label as a text
 */
enum ValidationDisplayType {
    COLOR,
    TEXT,
}

interface CheckFieldProps extends FormFieldProps {
    readonly type?: 'switch' | 'checkbox';
    readonly validationDisplayType?: ValidationDisplayType;
    readonly icon?: React.ReactNode;
}

export const CheckField: React.FC<CheckFieldProps> = ({
    name,
    id,
    label,
    helperText,
    type = 'checkbox',
    fieldRef,
    validationDisplayType = ValidationDisplayType.COLOR,
    icon,
    ...formControlProps
}) => {
    const { field, fieldState, formState } = useController({ name });
    const { onChange, onBlur, value, ref } = field;
    const { error } = fieldState;
    const { errors } = formState;
    const isError = Boolean(error);
    const [uid, helperUid, labelUid] = makeFieldIds(name, id);

    const Control = type === 'switch' ? Switch : Checkbox;

    const color = validationDisplayType === ValidationDisplayType.COLOR && has(errors, name) ? 'error.main' : undefined;

    return (
        <FormControl error={isError} {...formControlProps}>
            <FormControlLabel
                ref={fieldRef}
                id={labelUid}
                sx={{ '.MuiTypography-root': { color, '& span': { color } } }}
                label={
                    <>
                        {label}
                        {icon}
                    </>
                }
                control={
                    <Control
                        key={value}
                        value={value}
                        checked={value}
                        inputRef={ref}
                        id={uid}
                        name={name}
                        aria-describedby={helperUid}
                        data-cy="checkbox"
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                }
            />
            <FormHelperText id={helperUid}>
                {isError && validationDisplayType === ValidationDisplayType.TEXT ? (
                    <ErrorMessage error={error} />
                ) : (
                    helperText
                )}
            </FormHelperText>
        </FormControl>
    );
};
