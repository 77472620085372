import AirlineSeatLegroomNormalOutlinedIcon from '@mui/icons-material/AirlineSeatLegroomNormalOutlined';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoIcon } from '@/components/elements/icon/info-icon';
import { useLayout } from '@/components/page/layout/mod';
import { useAppCtx } from '@/core/app-ctx/mod';
import { type BookingBus } from '@/entity/bus/BookingBus';

interface BusSeatsInfoProps {
    readonly bus: BookingBus;
    readonly variant?: 'default' | 'seats' | 'bus-info';
    readonly hideInfoIconMobile?: boolean;
}

const BusSeatsInfo: React.FC<BusSeatsInfoProps> = ({ bus, hideInfoIconMobile, variant = 'default' }) => {
    const { t } = useTranslation();
    const { isSmallMobile } = useLayout();
    const brand = isSmallMobile ? bus.brand.name : bus.getBrandAndType();
    const { appSettings } = useAppCtx();

    const BusInfo = (
        <Typography variant="body2">
            {appSettings.branding.useBusName ? bus.name : `${brand}, ${bus.registrationDate.year}`}
        </Typography>
    );

    const Seats = (
        <Stack direction="row">
            <Typography variant="body2" display="flex" component="div" alignItems="center">
                {isSmallMobile ? <AirlineSeatLegroomNormalOutlinedIcon fontSize="inherit" /> : t('bus.seats')}
                &nbsp;&nbsp;
                {bus.getDisplaySeats()}
            </Typography>
            {!hideInfoIconMobile && <InfoIcon tooltipText={t('bus.tt_number_of_seats')} />}
        </Stack>
    );

    if (variant === 'bus-info') return BusInfo;

    if (variant === 'seats') return Seats;

    return (
        <Stack direction="row" gap={2}>
            {BusInfo}
            {Seats}
        </Stack>
    );
};

export default BusSeatsInfo;
