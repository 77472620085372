import { Box, type BoxProps } from '@mui/material';
import { useState, useEffect } from 'react';

import { useLocalization } from '@/core/localization/mod';

const svgModules = import.meta.glob<string>('./**/*.svg', { import: 'default', query: '?url', eager: false });

// Check if the path leads to an existing svg file
const getUrl = async (path: string) => {
    if (path in svgModules) {
        const modulePath = await svgModules[path]();
        return modulePath;
    }
    return null;
};
interface TranslatedSvgProps extends BoxProps {
    readonly path: string;
}

export function TranslatedSvg({ path, ...props }: TranslatedSvgProps) {
    const { language } = useLocalization();
    const [src, setSrc] = useState<string>();

    useEffect(() => {
        const loadSvgComponent = async () => {
            const localizedPath = `./${path}/${language}.svg`;
            const fallbackPath = `./${path}/en.svg`;

            const src = (await getUrl(localizedPath)) || (await getUrl(fallbackPath));
            if (src) setSrc(src);
        };

        void loadSvgComponent();
    }, [language, path]);

    if (!src) return null;

    return <Box {...props} src={src} component="img" />;
}
