import { Link } from '@mui/material';
import { useMatches } from '@tanstack/react-router';
import { Trans, useTranslation } from 'react-i18next';

export const SuccessText = ({ companyName }: { readonly companyName: string }) => {
    const { t } = useTranslation();
    const matches = useMatches();
    const isHome = matches.some(match => match.id === '/');

    if (isHome) return t('trip_company.successfully_added_no_link', { companyName });

    return (
        <Trans
            i18nKey="trip_company.successfully_added"
            values={{ companyName }}
            components={{
                anchor: (
                    <Link
                        href="/"
                        sx={{
                            fontWeight: 'bold',
                        }}
                    />
                ),
            }}
        />
    );
};
