import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { checkSchoolName } from '@/api/organization';
import { InfoIcon } from '@/components/elements/icon/info-icon';
import { CheckField } from '@/components/form/fields/check-field';
import { LocationField } from '@/components/form/fields/location-field';
import { SchoolAutocomplete } from '@/components/form/fields/school-autocomplete';
import { FormFieldRow } from '@/components/form/layout/form-field-row';

interface SchoolFieldsProps {
    readonly withOrganizationRegistration?: boolean;
    readonly onChange: (address: string) => void;
}

export const SchoolFields = ({ withOrganizationRegistration, onChange }: SchoolFieldsProps) => {
    const { t } = useTranslation();

    const [showRegisterCheckbox, setShowRegisterCheckbox] = useState(false);

    const handleBlur = async (schoolName: string) => {
        if (!schoolName || !withOrganizationRegistration) return setShowRegisterCheckbox(false);

        try {
            const {
                data: { isAvailable },
            } = await checkSchoolName(schoolName);

            setShowRegisterCheckbox(isAvailable);
        } catch {
            console.error('Error while checking association name');
        }
    };

    return (
        <>
            <FormFieldRow>
                <SchoolAutocomplete
                    label={t('school.autocomplete')}
                    name="organizationName"
                    onChangeAddress={onChange}
                    onBlurField={handleBlur}
                />

                <LocationField required fullWidth label={t('school.location')} name="organizationLocation" />
            </FormFieldRow>
            {showRegisterCheckbox && (
                <FormFieldRow>
                    <CheckField
                        name="registerOrganization"
                        label={t('school.register.label')}
                        icon={<InfoIcon tooltipText={t('school.register.tt_text')} />}
                    />
                </FormFieldRow>
            )}
        </>
    );
};
