import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '@/components/elements/action/action-button';
import { createLayout } from '@/components/page/layout/createLayout';
import { BookingNavigation } from '@/components/page/navigation/booking-navigation';

const NotFoundPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" alignItems="center" sx={{ minHeight: '100%' }} gap={3}>
            <Typography mt={8} variant="h1">
                404
            </Typography>
            <Typography gutterBottom variant="h2">
                {t('error404.title')}
            </Typography>
            <ActionButton navigate={{ to: '/', replace: true, search: {} }}>
                <Typography variant="body1" textTransform="capitalize">
                    {t('error404.back')}
                </Typography>
            </ActionButton>
        </Box>
    );
};

export const NotFound: React.FC = () => {
    const { Layout } = useMemo(
        () =>
            createLayout({
                tiles: {
                    'not-found': <NotFoundPage />,
                },
                base: ['not-found'],
            }),
        [],
    );

    return <Layout useNavigation={BookingNavigation} />;
};
