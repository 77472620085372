import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid2,
    Link,
    Switch,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { type CookieConsent, useCookieBanner, COOKIE_CONSENT_VALUES } from '@/core/cookie-banner/mod';

interface CookieCustomizationProps {
    readonly onChangeView: () => void;
    readonly onClose: () => void;
}

export const CookieCustomization: React.FC<CookieCustomizationProps> = ({ onChangeView, onClose }) => {
    const { t } = useTranslation();
    const { setCookieConsent } = useCookieBanner();
    const [preferences, setPreferences] = useState<CookieConsent[]>(COOKIE_CONSENT_VALUES);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const preference = event.target.name as CookieConsent;
        const checked = event.target.checked;

        if (checked) return setPreferences([...preferences, preference]);
        return setPreferences(preferences.filter(p => p !== preference));
    };

    const handleSave = () => {
        setCookieConsent(preferences);
        onClose();
    };

    return (
        <>
            <DialogTitle variant="h6">{t('cookies.preferences.title')}</DialogTitle>
            <DialogContent>
                <Grid2
                    container
                    sx={{
                        '.MuiFormControlLabel-root': {
                            display: isMobile ? 'flex' : 'inline-flex',
                            marginLeft: isMobile ? 0 : '16px',
                            justifyContent: 'space-between',
                        },
                    }}>
                    <Grid2 sx={{ mb: 3 }} size={12}>
                        <Typography>
                            <Trans
                                i18nKey="cookies.preferences.details"
                                components={{
                                    anchor: (
                                        <Link href="https://www.busfinder.com/datenschutzinformation" target="_blank" />
                                    ),
                                }}
                            />
                        </Typography>
                    </Grid2>

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 6,
                        }}>
                        <FormControlLabel
                            control={<Switch defaultChecked disabled name="necessary" />}
                            label={<Typography>{t('cookies.necessary')}</Typography>}
                            labelPlacement="start"
                        />
                    </Grid2>

                    {isMobile && (
                        <Grid2 size={12}>
                            <Divider
                                sx={{
                                    opacity: '0.6',
                                }}
                            />
                        </Grid2>
                    )}

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 6,
                        }}>
                        <FormControlLabel
                            control={<Switch defaultChecked name="marketing" onChange={handleChange} />}
                            label={<Typography>{t('cookies.marketing')}</Typography>}
                            labelPlacement="start"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Grid2 container justifyContent="space-between" gap={1} direction={isMobile ? 'column-reverse' : 'row'}>
                    <Grid2
                        size={{
                            xs: 12,
                            sm: 'auto',
                        }}>
                        <Button fullWidth variant="outlined" onClick={onChangeView}>
                            {t('cookies.go_back')}
                        </Button>
                    </Grid2>

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 'auto',
                        }}>
                        <Button fullWidth variant="contained" onClick={handleSave}>
                            {t('cookies.save')}
                        </Button>
                    </Grid2>
                </Grid2>
            </DialogActions>
        </>
    );
};
