import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { checkAssociationName } from '@/api/organization';
import { InfoIcon } from '@/components/elements/icon/info-icon';
import { AssociationAutocomplete } from '@/components/form/fields/association-autocomplete';
import { CheckField } from '@/components/form/fields/check-field';
import { LocationField } from '@/components/form/fields/location-field';
import { TextField } from '@/components/form/fields/text-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';

interface AssociationFieldsProps {
    readonly withOrganizationRegistration?: boolean;
    readonly onChange: (address: string) => void;
}

export const AssociationFields = ({ withOrganizationRegistration, onChange }: AssociationFieldsProps) => {
    const { control, setValue } = useFormContext();
    const { t } = useTranslation();

    const [showRegisterCheckbox, setShowRegisterCheckbox] = useState(false);
    const registerOrganization = useWatch({
        control,
        name: 'registerOrganization',
    });

    const handleBlur = async (associationName: string) => {
        if (!associationName || !withOrganizationRegistration) return setShowRegisterCheckbox(false);

        try {
            const {
                data: { isAvailable },
            } = await checkAssociationName(associationName);

            setShowRegisterCheckbox(isAvailable);
            setValue('registerOrganization', isAvailable);
        } catch {
            console.error('Error while checking association name');
        }
    };

    return (
        <>
            <FormFieldRow>
                <AssociationAutocomplete
                    label={t('association.autocomplete')}
                    name="organizationName"
                    onChangeAddress={onChange}
                    onBlurField={handleBlur}
                />

                <LocationField required fullWidth label={t('association.location')} name="organizationLocation" />
            </FormFieldRow>
            {showRegisterCheckbox && (
                <FormFieldRow>
                    <CheckField
                        name="registerOrganization"
                        label={t('association.register.label')}
                        icon={<InfoIcon tooltipText={t('association.register.tt_text')} />}
                    />
                </FormFieldRow>
            )}
            {showRegisterCheckbox && registerOrganization && (
                <FormFieldRow insertEmptyColumn>
                    <TextField required name="registrationNumber" label={t('association.registration_number')} />
                </FormFieldRow>
            )}
        </>
    );
};
