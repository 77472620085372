import { styled } from '@mui/material';

export const EditorInner = styled('div')(({ theme }) => ({
    margin: theme.spacing(1),
    position: 'relative',
    backgroundColor: theme.vars.palette.background.paper,
    '& .editor-input': {
        minHeight: 200,
        resize: 'none',
        position: 'relative',
        tabSize: 1,
        outline: 'none',
    },
}));
