import { mergeRegister } from '@lexical/utils';
import RedoIcon from '@mui/icons-material/RedoOutlined';
import UndoIcon from '@mui/icons-material/UndoOutlined';
import { CAN_REDO_COMMAND, CAN_UNDO_COMMAND, type LexicalEditor, REDO_COMMAND, UNDO_COMMAND } from 'lexical';
import { type FC, useEffect, useState } from 'react';

import { ToolbarButton } from './toolbar-button';
import { ToolbarGroup } from './toolbar-group';

const LowPriority = 1;

interface HistoryControlsProps {
    readonly editor: LexicalEditor;
}

export const HistoryControls: FC<HistoryControlsProps> = ({ editor }) => {
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                payload => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority,
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                payload => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority,
            ),
        );
    }, [editor]);

    return (
        <ToolbarGroup>
            <ToolbarButton
                disabled={!canUndo}
                icon={<UndoIcon />}
                onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
            />
            <ToolbarButton
                disabled={!canRedo}
                icon={<RedoIcon />}
                onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
            />
        </ToolbarGroup>
    );
};
