import AccommodationIcon from '@mui/icons-material/ApartmentOutlined';
import ActivityIcon from '@mui/icons-material/AttractionsOutlined';
import AnyTripIcon from '@mui/icons-material/FlagOutlined';
import FoodIcon from '@mui/icons-material/FoodBankOutlined';
import { type FC } from 'react';

import { Pin } from './pin';

import { type TripCategoryGroup } from '@/entity/trip/category/TripCategory';

interface TripPinProps {
    readonly category?: TripCategoryGroup;
}

export const TripPin: FC<TripPinProps> = ({ category }) => {
    return (
        <Pin>
            {category == null && <AnyTripIcon fontSize="inherit" htmlColor="inherit" />}
            {category === 'ACCOMMODATION' && <AccommodationIcon fontSize="inherit" htmlColor="inherit" />}
            {category === 'ACTIVITY' && <ActivityIcon fontSize="inherit" htmlColor="inherit" />}
            {category === 'FOOD' && <FoodIcon fontSize="inherit" htmlColor="inherit" />}
        </Pin>
    );
};
