import { type LexicalEditor, ParagraphNode, type SerializedParagraphNode } from 'lexical';

export class CustomParagraphNode extends ParagraphNode {
    static getType() {
        return 'custom-paragraph';
    }

    static clone(node: CustomParagraphNode) {
        return new CustomParagraphNode(node.__key);
    }

    exportJSON(): SerializedParagraphNode {
        return {
            ...super.exportJSON(),
            type: CustomParagraphNode.getType(),
            version: 1,
        };
    }

    exportDOM(editor: LexicalEditor) {
        if (this.isEmpty()) return { element: null };
        return super.exportDOM(editor);
    }
}
