import Close from '@mui/icons-material/Close';
import {
    Dialog,
    DialogContent,
    Grid2,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect } from 'react';
import { ReactCountryFlag } from 'react-country-flag';
import ga4 from 'react-ga4';
import { useTranslation } from 'react-i18next';

import { useUpdateLanguage } from '@/api/user/mutations';
import { useLayout } from '@/components/page/layout/mod';
import {
    DEFAULT_CURRENCY,
    SUPPORTED_CURRENCIES,
    SUPPORTED_REGIONS,
    supportedLanguages,
    type Language,
} from '@/config/localization';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useAuth } from '@/core/auth/mod';
import { useLocalization } from '@/core/localization/mod';

const COLUMN_WIDTH = 250;

export const SettingsActions: React.FC = () => {
    const [open, setOpen] = React.useState(false);

    const { region, language, currency, setAndSaveRegion, setAndSaveLanguage, setCurrency } = useLocalization();
    const { t } = useTranslation();
    const { mutate: updateLanguage } = useUpdateLanguage();
    const { user } = useAuth();
    const {
        appSettings: {
            localization: { disableCurrencySwitcher, disableLanguageSwitcher, disableRegionSwitcher },
        },
    } = useAppCtx();
    const { isDesktop } = useLayout();

    const displayedSwitcherCount = [disableCurrencySwitcher, disableLanguageSwitcher, disableRegionSwitcher].filter(
        d => !d,
    ).length;

    const md = 12 / displayedSwitcherCount;

    const containerWidth = displayedSwitcherCount * COLUMN_WIDTH;

    useEffect(() => {
        if (disableCurrencySwitcher && currency !== DEFAULT_CURRENCY) setCurrency(DEFAULT_CURRENCY);
    }, [currency, disableCurrencySwitcher, setCurrency]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleUpdateLanguage = () => {
        if (user?.isCustomer()) updateLanguage(user.id);
    };
    const handleLanguageChange = (lng: Language) => () => {
        setAndSaveLanguage(lng);
        handleUpdateLanguage();
        ga4.event('misc', {
            event_category: 'change-language',
            event_label: lng,
        });
    };

    const id = uniqueId('menu');

    const lngLabel = (lng: Language): string => {
        const parts = [t(`localization:language.${lng}`)];

        if (lng !== 'cimode' && lng !== language) {
            const native = t(`localization:language.${lng}`, { lng });
            parts.push(`(${native})`);
        }

        return parts.join(' ');
    };

    if (displayedSwitcherCount === 0) return null;

    return (
        <>
            <IconButton aria-haspopup color="inherit" aria-controls={id} onClick={handleOpen}>
                <ReactCountryFlag svg countryCode={region} />
            </IconButton>
            <Dialog
                open={open}
                maxWidth="lg"
                id={id}
                PaperProps={{
                    sx: { position: 'relative', width: 'fit-content' },
                }}
                onClose={handleClose}>
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        zIndex(theme) {
                            return `calc(${theme.vars.zIndex.drawer} + 1)`;
                        },
                    }}
                    onClick={handleClose}>
                    <Close />
                </IconButton>
                <DialogContent>
                    <Grid2 container spacing={2} width={isDesktop ? containerWidth : '100%'}>
                        {/* Language */}
                        {!disableLanguageSwitcher && (
                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 12,
                                    md,
                                }}>
                                <Typography variant="h6" component="div">
                                    {t('language', { count: 2 })}
                                </Typography>
                                <List>
                                    {supportedLanguages().map(lng => (
                                        <ListItemButton
                                            key={lng}
                                            selected={lng === language}
                                            onClick={handleLanguageChange(lng)}>
                                            <ListItemText primary={lngLabel(lng)} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Grid2>
                        )}
                        {/* Region */}
                        {!disableRegionSwitcher && (
                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 12,
                                    md,
                                }}>
                                <Typography variant="h6" component="div">
                                    {t('region', { count: 2 })}
                                </Typography>
                                <List>
                                    {SUPPORTED_REGIONS.map(item => (
                                        <ListItemButton
                                            key={item}
                                            selected={item === region}
                                            onClick={() => setAndSaveRegion(item)}>
                                            <ListItemIcon>
                                                <ReactCountryFlag svg countryCode={item} />
                                            </ListItemIcon>
                                            <ListItemText primary={t(`countries:${item}`)} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Grid2>
                        )}
                        {/* Currency */}
                        {!disableCurrencySwitcher && (
                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 12,
                                    md,
                                }}>
                                <Typography variant="h6" component="div">
                                    {t('currency', { count: 2 })}
                                </Typography>
                                <List>
                                    {SUPPORTED_CURRENCIES.map(item => {
                                        const symbol = new Intl.NumberFormat(language, {
                                            style: 'currency',
                                            currency: item,
                                        })
                                            .formatToParts()
                                            .find(part => part.type === 'currency')?.value;

                                        return (
                                            <ListItemButton
                                                key={item}
                                                selected={item === currency}
                                                onClick={() => setCurrency(item)}>
                                                <ListItemText primary={symbol} />
                                            </ListItemButton>
                                        );
                                    })}
                                </List>
                            </Grid2>
                        )}
                    </Grid2>
                </DialogContent>
            </Dialog>
        </>
    );
};
