import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { Divider } from '@mui/material';
import { $getSelection, $isRangeSelection, $isRootOrShadowRoot, SELECTION_CHANGE_COMMAND } from 'lexical';
import { type FC, useCallback, useEffect, useState } from 'react';

import { type BlockType } from '../../types';

import { BlockFormatDropdown } from './block-format-dropdown';
import { HistoryControls } from './history-controls';
import { TextFormatButtons } from './text-format-buttons';
import { ToolbarGroup } from './toolbar-group';

const LowPriority = 1;

export const ToolbarPlugin: FC = () => {
    const [editor] = useLexicalComposerContext();

    // Selected Block Type
    const [blockType, setBlockType] = useState<BlockType>('paragraph');

    const [isEditable, setIsEditable] = useState(() => editor.isEditable());

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();

            let element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : $findMatchingParent(anchorNode, e => {
                          const parent = e.getParent();
                          return parent !== null && $isRootOrShadowRoot(parent);
                      });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);

            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
                    const type = parentList ? parentList.getListType() : element.getListType();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();

                    setBlockType(type as BlockType);
                }
            }
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener(editable => {
                setIsEditable(editable);
            }),
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, _newEditor) => {
                    $updateToolbar();
                    return false;
                },
                LowPriority,
            ),
        );
    }, [editor, $updateToolbar]);

    return (
        <ToolbarGroup
            sx={{
                backgroundColor: theme => theme.vars.palette.grey[100],
            }}>
            <HistoryControls editor={editor} />
            <Divider flexItem orientation="vertical" />
            <TextFormatButtons editor={editor} />
            <Divider flexItem orientation="vertical" />
            <BlockFormatDropdown disabled={!isEditable} blockType={blockType} editor={editor} />
        </ToolbarGroup>
    );
};
