import { styled } from '@mui/material';

export const Placeholder = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    top: 0,
    left: 0,
    position: 'absolute',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    display: 'inline-block',
    pointerEvents: 'none',
    color: theme.vars.palette.text.disabled,
}));
