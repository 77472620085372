import { Box, Stack, Typography, type TypographyProps } from '@mui/material';
import React, { type ReactNode } from 'react';

import { iff } from '@/utils/helpers';

type HeadingSize = 'small' | 'medium' | 'large';

const SIZE_MAP: Record<HeadingSize, [TypographyProps['variant'], TypographyProps['variant']]> = {
    small: ['h5', 'h5Sub'],
    medium: ['h4', 'h4Sub'],
    large: ['h3', 'h3Sub'],
};

interface HeadingProps {
    readonly title: ReactNode | ReactNode[];
    readonly subtitle?: ReactNode;
    readonly size?: 'small' | 'medium' | 'large';
    readonly color?: TypographyProps['color'];
    readonly disableGutterBottom?: boolean;
    readonly underline?: boolean;
}

export const Heading: React.FC<HeadingProps> = ({
    title,
    subtitle,
    size = 'medium',
    color,
    disableGutterBottom,
    underline,
}) => {
    return (
        <Box mb={disableGutterBottom ? 0 : 1}>
            <Typography variant={SIZE_MAP[size][0]} color={color} sx={{ textDecoration: iff(underline, 'underline') }}>
                {Array.isArray(title) ? (
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        {title.map((p, i) => (
                            <span key={i}>{p}</span>
                        ))}
                    </Stack>
                ) : (
                    title
                )}
            </Typography>
            {subtitle && (
                <Typography variant={SIZE_MAP[size][1]} color={color}>
                    {subtitle}
                </Typography>
            )}
        </Box>
    );
};
