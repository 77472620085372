import { Grid2, type GridSize } from '@mui/material';
import React from 'react';

interface GridTileProps {
    readonly md?: GridSize;
    readonly lg?: GridSize;
}

export const GridTile: React.FC<React.PropsWithChildren<GridTileProps>> = ({ children, md = 'grow', lg = false }) => {
    return (
        <Grid2
            sx={{
                flexGrow: 1,
            }}
            size={{
                xs: 12,
                md,
                lg,
            }}>
            {children}
        </Grid2>
    );
};
