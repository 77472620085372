import AddLocationAltOutlined from '@mui/icons-material/AddLocationAltOutlined';
import { IconButton } from '@mui/material';
import React from 'react';
import ga4 from 'react-ga4';
import { useTranslation } from 'react-i18next';

import { useSnack } from '@/core/snack/mod';
import { type IPosition, useJourneyPlanningForm } from '@/features/journey-planning';
import { MAX_STOPS } from '@/utils/constants';

interface StopAddProps {
    readonly position: IPosition;
    readonly isAnyFieldBeingDragged: boolean;
}

export const StopAdd: React.FC<StopAddProps> = ({ position, isAnyFieldBeingDragged }) => {
    const {
        stopsArray,
        formMethods: {
            formState: { isValid },
        },
    } = useJourneyPlanningForm();
    const { t } = useTranslation();
    const snack = useSnack();

    const { insert, fields } = stopsArray;

    const disabled = !isValid;

    const handleInsertIntermediate = () => {
        if (fields.length >= MAX_STOPS) {
            snack.push(t('search_form.error.max_stops'), 'error');
        } else {
            insert(
                position.index + 1,
                {
                    location: null,
                    localBus: null,
                    departureDateTime: null,
                    arrivalDateTime: null,
                    tripCompany: null,
                },
                {
                    shouldFocus: true,
                },
            );
            ga4.event('search-form', { event_category: 'add-stop' });
        }
    };

    if (position.last || isAnyFieldBeingDragged) return null;

    return (
        <IconButton
            color="primary"
            disabled={disabled}
            size="small"
            data-cy="add-intermediate"
            onClick={handleInsertIntermediate}>
            <AddLocationAltOutlined color="inherit" />
        </IconButton>
    );
};
