import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ActionLink } from '@/components/elements/action/action-link';
import { Heading } from '@/components/elements/heading';
import { Tile } from '@/components/page/layout/tile/tile';
import { TilePart } from '@/components/page/layout/tile/tile-part';

export const LoginLeafletTile: React.FC = () => {
    const { t } = useTranslation();

    const sections = [
        {
            title: t('login_page.leaflet.welcome_back.title'),
            details: (
                <Trans
                    i18nKey="login_page.leaflet.welcome_back.details"
                    components={{
                        anchor: <ActionLink navigate={{ to: '/registration', search: {}, replace: true }} />,
                    }}
                />
            ),
        },
        {
            title: t('login_page.leaflet.questions.title'),
            details: (
                <Trans
                    i18nKey="login_page.leaflet.questions.details"
                    components={{ anchor: <Link href="https://www.busfinder.com/kontakt" target="_blank" /> }}
                />
            ),
        },
    ];

    return (
        <Tile leafletBackground>
            <TilePart>
                <Stack spacing={4}>
                    {sections.map(section => (
                        <Box key={section.title}>
                            <Heading color="primary" title={section.title} />
                            <Typography variant="body2">{section.details}</Typography>
                        </Box>
                    ))}
                </Stack>
            </TilePart>
        </Tile>
    );
};
