import { RichTextEditor } from '@/components/elements/rich-text-editor';
import { createLayout } from '@/components/page/layout/createLayout';
import { Tile } from '@/components/page/layout/tile/tile';
import { useColorScheme } from '@mui/material';
import { createFileRoute } from '@tanstack/react-router';
import { FC, useEffect, useMemo } from 'react';

export const Dev: FC = () => {
    const mode = useColorScheme();

    useEffect(() => {
        console.log(mode);
    }, [mode]);
    const { Layout } = useMemo(
        () =>
            createLayout({
                tiles: {
                    secondary: <Tile>{/* <TilePart>aaa</TilePart> */}</Tile>,
                    primary: (
                        <Tile sx={{ border: '1px solid black' }}>
                            <RichTextEditor />
                        </Tile>
                    ),
                },
                base: [
                    { name: 'secondary', md: 3 },
                    { name: 'primary', md: 9, mobileBase: true },
                ],
            }),
        [],
    );

    return <Layout />;
};

// const SEARCH_PARAMS = object({
//     token: string().required(),
// });

export const Route = createFileRoute('/dev')({
    component: Dev,
    // validateSearch(search): InferType<typeof SEARCH_PARAMS> {
    //     return SEARCH_PARAMS.cast(search);
    // },
    // onError(error) {
    //     if (error.routerCode === 'VALIDATE_SEARCH') redirect({ to: '/', replace: true, throw: true });
    // },
});
