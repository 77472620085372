import { useGoogleMap } from '@react-google-maps/api';
import { type PropsWithChildren, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { RoutePin } from './route-pin';
import { TripPin } from './trip-pin';

import { type DefaultStop } from '@/entity/journey/saved/DefaultStop';
import { type BookingStop } from '@/entity/journey/stop/BookingStop';
import { type IPosition } from '@/features/journey-planning';

interface MapMarkerProps {
    readonly stop: BookingStop | DefaultStop;
    readonly position: IPosition;
}

export default function MapMarker({ position, stop }: MapMarkerProps) {
    const latLng = stop.location.toGMapsLatLng();

    return (
        <OverlayView latLng={latLng}>{stop.tripCompany ? <TripPin /> : <RoutePin position={position} />}</OverlayView>
    );
}

interface OverlayProps {
    readonly latLng: google.maps.LatLng | google.maps.LatLngLiteral;
    readonly pane?: keyof google.maps.MapPanes;
}

// TODO: Overlays are note the most suitable way to render markers.
// As of recently we could use the new AdvancedMarker API of Google Maps,
// however this probably requires a migration to, preferably https://github.com/visgl/react-google-maps
// or a similar library.
function OverlayView({ latLng, pane = 'floatPane', children }: PropsWithChildren<OverlayProps>) {
    const map = useGoogleMap();

    const container = useMemo(() => {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        return div;
    }, []);

    const overlay = useMemo(() => {
        return createOverlay(container, pane, latLng);
    }, [container, pane, latLng]);

    useEffect(() => {
        overlay?.setMap(map);
        return () => overlay?.setMap(null);
    }, [map, overlay]);

    // TODO: Fix type issue if I remove the Fragment
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20942
    return <>{createPortal(children, container)}</>;
}

// At build time google.maps is not available yet, so we wrap the the class in a factory function, so this class will be created at runtime
function createOverlay(
    container: HTMLElement,
    pane: keyof google.maps.MapPanes,
    latLng: google.maps.LatLng | google.maps.LatLngLiteral,
) {
    class Overlay extends google.maps.OverlayView {
        container: HTMLElement;
        pane: keyof google.maps.MapPanes;
        latLng: google.maps.LatLng | google.maps.LatLngLiteral;

        constructor(
            container: HTMLElement,
            pane: keyof google.maps.MapPanes,
            latLng: google.maps.LatLng | google.maps.LatLngLiteral,
        ) {
            super();
            this.container = container;
            this.pane = pane;
            this.latLng = latLng;
        }

        onAdd() {
            const pane = this.getPanes()?.[this.pane];
            pane?.append(this.container);
        }

        draw() {
            const projection = this.getProjection();
            const point = projection.fromLatLngToDivPixel(this.latLng);

            if (point === null) return;

            this.container.style.left = `${point.x}px`;
            this.container.style.top = `${point.y}px`;
        }

        onRemove() {
            if (this.container.parentNode !== null) {
                this.container.remove();
            }
        }
    }
    return new Overlay(container, pane, latLng);
}
