import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Grid2 } from '@mui/material';
import { type NavigateOptions } from '@tanstack/react-router';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { loginSchema, type ILogin } from '../types/login-form';

import { PwResetRequestForm } from './pw-reset-request-form';

import type APIError from '@/api/APIError';
import { ActionButton } from '@/components/elements/action/action-button';
import { ActionLoadingButton } from '@/components/elements/action/action-loading-button';
import { TextField } from '@/components/form/fields/text-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';
import { FormFieldsContainer } from '@/components/form/layout/form-fields-container';
import { useAuth } from '@/core/auth/mod';
import { Dialog } from '@/features/ui';
import { useDisclosure } from '@/hooks/useDisclosure';

interface LoginFormProps {
    readonly loginCallback?: () => void;
    readonly navigate?: NavigateOptions;
    readonly redirectHref?: string;
    readonly didLogout?: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({ loginCallback, navigate, redirectHref, didLogout }) => {
    const { login, isLoggingIn } = useAuth();
    const { t } = useTranslation();
    const pwResetRequestDialog = useDisclosure();

    const [error, setError] = useState<APIError | undefined>();

    const formMethods = useForm<ILogin>({
        resolver: yupResolver(loginSchema),
        mode: 'onChange',
    });

    const onSubmit = async (values: ILogin) => {
        try {
            setError(undefined);
            await login(values, {
                navigate,
                redirectHref,
            });
            loginCallback?.();
        } catch (error_) {
            setError(error_ as APIError);
        }
    };

    return (
        <>
            <FormProvider {...formMethods}>
                <form noValidate onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <FormFieldsContainer>
                        <FormFieldRow>
                            <TextField name="username" label={t('email')} data-cy="login-email-field" />
                        </FormFieldRow>

                        <FormFieldRow>
                            <TextField
                                name="password"
                                label={t('password')}
                                type="password"
                                data-cy="login-password-field"
                            />
                        </FormFieldRow>

                        <Grid2 container sx={{ justifyContent: 'space-between' }} size={12}>
                            <ActionButton category="secondary" onClick={pwResetRequestDialog.open}>
                                {t('forgot_password.link')}
                            </ActionButton>

                            <ActionLoadingButton
                                gaEvent={{ action: 'auth', category: 'login' }}
                                loading={isLoggingIn}
                                type="submit"
                                data-cy="login-button">
                                {t('login')}
                            </ActionLoadingButton>
                        </Grid2>
                    </FormFieldsContainer>
                    <LoginStatus error={error} isLogout={didLogout && !isLoggingIn} />
                </form>
            </FormProvider>
            <Dialog
                {...pwResetRequestDialog}
                variant="form"
                title={t('forgot_password.title')}
                description={t('forgot_password.text')}
                actions={{
                    reset: false,
                }}>
                <PwResetRequestForm />
            </Dialog>
        </>
    );
};

interface LoginStatusProps {
    readonly error?: APIError;
    readonly isLogout?: boolean;
}

const LoginStatus: React.FC<LoginStatusProps> = ({ error, isLogout }) => {
    const { t } = useTranslation();

    if (error) return <Alert severity="error">{t('validations:login.wrong_credentials')}</Alert>;
    if (isLogout) return <Alert severity="success">{t('validations:account.logout.success')}</Alert>;

    return null;
};
