import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $insertNodes } from 'lexical';
import { useEffect, useState, type FC } from 'react';

interface OnInitializePluginProps {
    readonly initialValue?: string;
}

export const OnInitializePlugin: FC<OnInitializePluginProps> = ({ initialValue }) => {
    const [editor] = useLexicalComposerContext();
    const [initialized, setInitialized] = useState(initialValue == null);

    useEffect(() => {
        if (initialized || initialValue == null) return;
        setInitialized(true);

        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(initialValue, 'text/html');
            const nodes = $generateNodesFromDOM(editor, dom);

            // Select the root
            $getRoot().select();
            $insertNodes(nodes);
        });
    }, [editor, initialValue, initialized]);

    return null;
};
