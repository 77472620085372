import { Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { InfoIcon } from '@/components/elements/icon/info-icon';
import { useAppCtx } from '@/core/app-ctx/mod';

interface BusRoomProps {
    variant: 'short' | 'long';
    userProvides?: boolean;
    companyProvides?: boolean;
}

export const BusRoom: React.FC<BusRoomProps> = ({ variant, userProvides, companyProvides }) => {
    const { t } = useTranslation();
    const { integration } = useAppCtx();

    const company = integration?.companyName ?? t('driver_rooms_choice.modal.company');

    if (variant === 'short') {
        if (companyProvides)
            return (
                <Stack direction="row">
                    <Typography variant="body2">{t('driver_rooms_choice.company_provides.short')}</Typography>
                    <InfoIcon tooltipText={t('driver_rooms_choice.company_provides.long', { company })} />
                </Stack>
            );
        if (userProvides)
            return (
                <Stack direction="row">
                    <Typography variant="body2">{t('driver_rooms_choice.customer_provides.short')}</Typography>
                    <InfoIcon
                        tooltipText={
                            <Trans
                                i18nKey="driver_rooms_choice.customer_provides.long"
                                components={{
                                    b: <b />,
                                }}
                            />
                        }
                    />
                </Stack>
            );
    }

    if (companyProvides) {
        return <Typography variant="body2">{t('driver_rooms_choice.company_provides.long', { company })}</Typography>;
    }
    if (userProvides)
        return (
            <Typography variant="body2">
                <Trans
                    i18nKey="driver_rooms_choice.customer_provides.long"
                    components={{
                        b: <b />,
                    }}
                />
            </Typography>
        );
    return null;
};
