import { type PropsWithChildren, type FC } from 'react';

import { I18NProvider } from './I18NProvider';
import { LocalizationProvider } from './LocalizationProvider';

export const LocalizationProviderSetup: FC<PropsWithChildren> = ({ children }) => {
    return (
        <I18NProvider>
            <LocalizationProvider>{children}</LocalizationProvider>
        </I18NProvider>
    );
};
