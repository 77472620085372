import { createContext, useContext, useMemo } from 'react';

import { type BaseModalProps } from '@/components/modals/defaults/BaseModal';
import { type JourneyConsiderationsProps } from '@/components/modals/JourneyConsiderationsModal';
import { type SaveJourneyModalProps } from '@/components/modals/SaveJourneyModal';
import { type PaymentSelectorModalProps } from '@/features/payment-selection';

export enum ModalTypes {
    SAVE_JOURNEY_MODAL = 'SAVE_JOURNEY_MODAL',
    JOURNEY_CONSIDERATIONS_MODAL = 'JOURNEY_CONSIDERATIONS_MODAL',
}

type OmitHandleClose<T> = Omit<T, 'handleClose'>;

type ModalProps = OmitHandleClose<BaseModalProps | SaveJourneyModalProps | PaymentSelectorModalProps>;

export interface ModalState {
    modalId: ModalTypes;
    modalProps: ModalProps;
}

export interface IModalContext {
    modals: ModalState[];
    setModal: (modalState: ModalState) => void;
}

export const ModalContext = createContext<IModalContext>({
    modals: [],
    setModal() {},
});

export function useModal() {
    const context = useContext(ModalContext);
    const { setModal } = context;

    return useMemo(() => {
        return {
            openSaveJourneyModal(modalProps: OmitHandleClose<SaveJourneyModalProps>) {
                setModal({ modalId: ModalTypes.SAVE_JOURNEY_MODAL, modalProps });
            },
            openJourneyConsiderationsModal(modalProps: OmitHandleClose<JourneyConsiderationsProps>) {
                setModal({ modalId: ModalTypes.JOURNEY_CONSIDERATIONS_MODAL, modalProps });
            },
        };
    }, [setModal]);
}
