import { DialogContent, DialogContentText } from '@mui/material';
import { type FC, type ReactNode } from 'react';

export interface DialogDescriptionProps {
    readonly id: string;
    readonly description: ReactNode;
}

export const DialogDescription: FC<DialogDescriptionProps> = ({ id, description }) => {
    return (
        <DialogContent>
            {typeof description === 'string' ? (
                <DialogContentText id={id}>{description}</DialogContentText>
            ) : (
                description
            )}
        </DialogContent>
    );
};
