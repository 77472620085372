import { DateTime } from 'luxon';

import { Account } from '@/entity/account/Account';
import { Location } from '@/entity/basic/Location';
import { CustomerGroupType } from '@/entity/common-constants';
import { CustomerGroup } from '@/entity/customergroup/CustomerGroup';

export class Customer extends Account {
    public organizationName: string;

    public organizationUID: string;

    public organizationLocation?: Location;

    public registrationSubmitted: boolean;

    public registrationConfirmed: boolean;

    public subscribedToNewsletter: boolean;

    public verifiedAt: DateTime;

    public registered: boolean;

    public verified: boolean;

    public customerGroup: CustomerGroup;

    public ratioCustomerId?: number;

    public customerReferredUser?: boolean;

    constructor(json: Record<string, any>) {
        super(json);
        this.registrationSubmitted = json.registrationSubmitted;
        this.registrationConfirmed = json.registrationConfirmed;
        this.organizationName = json.organizationName;
        this.organizationLocation = json.organizationLocation
            ? Location.fromJson(json.organizationLocation)
            : undefined;
        this.organizationUID = json.organizationUID;
        this.subscribedToNewsletter = json.subscribedToNewsletter;
        this.verifiedAt = DateTime.fromISO(json.verifiedAt);
        this.registered = json.registered;
        this.verified = json.verified;
        this.customerGroup = new CustomerGroup(json.customerGroup);
        this.ratioCustomerId = json.ratioCustomerId;
        this.customerReferredUser = json.customerDidReferAccount;
    }

    isPrivate() {
        return this.customerGroup.type === CustomerGroupType.PRIVATE;
    }

    isSchool() {
        return this.customerGroup.type === CustomerGroupType.SCHOOL;
    }

    isSchoolOrBusinessOrAssociation() {
        return (
            this.customerGroup.type === CustomerGroupType.SCHOOL ||
            this.customerGroup.type === CustomerGroupType.BUSINESS ||
            this.customerGroup.type === CustomerGroupType.ASSOCIATION
        );
    }

    isSchoolOrBusiness() {
        return (
            this.customerGroup.type === CustomerGroupType.SCHOOL ||
            this.customerGroup.type === CustomerGroupType.BUSINESS
        );
    }

    isBusiness() {
        return this.customerGroup.type === CustomerGroupType.BUSINESS;
    }

    isReseller() {
        return this.customerGroup.type === CustomerGroupType.RESELLER;
    }

    isAssociation() {
        return this.customerGroup.type === CustomerGroupType.ASSOCIATION;
    }

    didBookWithRegistration() {
        return !this.registrationConfirmed && this.registrationSubmitted;
    }

    getRoleName() {
        return `${this.role} (${this.customerGroup.type})`;
    }
}
