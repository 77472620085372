import { Box, Grid2, type Grid2Props, Typography } from '@mui/material';
import React from 'react';

import { useLayout } from '@/components/page/layout/mod';

interface IFormFieldsContainerProps extends Grid2Props {
    readonly containerLabel?: string;
}

export const FormFieldsContainer: React.FC<IFormFieldsContainerProps> = props => {
    const { children, containerLabel, ...rest } = props;
    const { isDesktop } = useLayout();

    return (
        <Box sx={{ mb: 3 }}>
            {containerLabel && (
                <Typography sx={{ mb: 2 }} variant="h5">
                    {containerLabel}
                </Typography>
            )}
            <Grid2 container columnSpacing={isDesktop ? 2 : 1} spacing={1} size={12} {...rest}>
                {children}
            </Grid2>
        </Box>
    );
};
