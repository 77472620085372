import { styled } from '@mui/material';
import { type FC, type PropsWithChildren } from 'react';

const PinRoot = styled('div')({
    '--pin-size': '2rem',
    transform: 'translate(-50%, -100%)',
});

const PinHead = styled('div')(({ theme }) => ({
    fontSize: 'calc(var(--pin-size) * 0.7)',
    position: 'relative',
    width: 'var(--pin-size)',
    height: 'var(--pin-size)',
    borderRadius: '50% 50% 0 50%',
    alignContent: 'center',
    backgroundColor: theme.vars.palette.MapPin.bg,
    color: theme.vars.palette.MapPin.color,
    borderColor: theme.vars.palette.MapPin.border,
    borderStyle: 'solid',
    borderWidth: '0.1em',
    rotate: '45deg',
    boxShadow: theme.vars.shadows[2],
    ':before': {
        content: '""',
        position: 'absolute',
        rotate: '-45deg',

        bottom: '-5%',
        right: '-5%',
        width: '10%',
        height: '10%',
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        boxShadow: `0 0 calc(var(--pin-size) * 0.2) calc(var(--pin-size) * 0.1) rgba(0, 0, 0, 0.12)`,
        transform: 'rotateX(85deg)',
    },
}));

const PinContent = styled('div')({
    rotate: '-45deg',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

export const Pin: FC<PropsWithChildren> = ({ children }) => {
    return (
        <PinRoot>
            <PinHead>
                <PinContent>{children}</PinContent>
            </PinHead>
        </PinRoot>
    );
};
