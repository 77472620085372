import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { ParagraphNode, TextNode } from 'lexical';
import { type FC } from 'react';

import { CustomParagraphNode } from '../nodes/CustomParagraphNode';
import { CustomTextNode } from '../nodes/CustomTextNode';

import { EditorContainer } from './editor-container';
import { EditorInner } from './editor-inner';
import { OnChangePlugin } from './on-change-plugin';
import { OnInitializePlugin } from './on-initialize-plugin';
import { Placeholder } from './placeholder';
import { theme } from './theme';
import { ToolbarPlugin } from './toolbar-plugin/toolbar-plugin';

interface RichTextEditorProps {
    readonly initialValue?: string;
    readonly placeholder?: string;
    readonly id?: string;
    readonly onChange?: (value: string) => void;
}

export const RichTextEditor: FC<RichTextEditorProps> = ({ initialValue, placeholder, id, onChange }) => {
    const initialConfig = {
        namespace: 'RTE',
        theme,
        onError(error: Error) {
            throw error;
        },
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            CustomParagraphNode,
            CustomTextNode,
            { replace: ParagraphNode, with: () => new CustomParagraphNode() },
            { replace: TextNode, with: (node: TextNode) => new CustomTextNode(node.__text) },
        ],
    };

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <EditorContainer id={id}>
                <ToolbarPlugin />
                <EditorInner>
                    <RichTextPlugin
                        contentEditable={<ContentEditable className="editor-input" />}
                        placeholder={<Placeholder>{placeholder}</Placeholder>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <ListPlugin />
                    <HistoryPlugin />
                    <TabIndentationPlugin />
                    <AutoFocusPlugin />
                    <OnInitializePlugin initialValue={initialValue} />
                    <OnChangePlugin onChange={onChange} />
                </EditorInner>
            </EditorContainer>
        </LexicalComposer>
    );
};
