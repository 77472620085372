import { type Basic } from '@/entity/basic/I_Basic';

export class Co2BusCalculation implements Basic<Co2BusCalculation> {
    public static fromJson(json: Record<string, any>): Co2BusCalculation {
        return new Co2BusCalculation(
            json.requiredCars,
            json.co2PerPersonCarInKg,
            json.savingsBusToCar,
            json.co2PerPersonBusInKg,
            json.totalBusPax,
            json.averageFuelConsumptionBus,
            json.travelDistanceInKm,
            json.remainingPax,
            json.isHvo100,
        );
    }

    public requiredCars: number;

    public co2PerPersonCarInKg: number;

    public savingsBusToCar: number;

    public co2PerPersonBusInKg: number;

    public totalBusPax: number;

    public averageFuelConsumptionBus: number;

    public travelDistanceInKm: number;

    public remainingPax: number;

    public isHvo100: boolean;

    constructor(
        requiredCars: number,
        co2PerPersonCarInKg: number,
        savingsBusToCar: number,
        co2PerPersonBusInKg: number,
        totalBusPax: number,
        averageFuelConsumptionBus: number,
        travelDistanceInKm: number,
        remainingPax: number,
        isHvo100: boolean,
    ) {
        this.requiredCars = requiredCars;
        this.co2PerPersonCarInKg = co2PerPersonCarInKg;
        this.savingsBusToCar = savingsBusToCar;
        this.co2PerPersonBusInKg = co2PerPersonBusInKg;
        this.totalBusPax = totalBusPax;
        this.averageFuelConsumptionBus = averageFuelConsumptionBus;
        this.travelDistanceInKm = travelDistanceInKm;
        this.remainingPax = remainingPax;
        this.isHvo100 = isHvo100;
    }

    netSavingsPerPerson() {
        const difference = this.co2PerPersonCarInKg - this.co2PerPersonBusInKg;
        return Number.parseFloat(difference.toFixed(2));
    }

    public equals(other: Co2BusCalculation): boolean {
        return (
            other != null &&
            this.requiredCars === other.requiredCars &&
            this.co2PerPersonCarInKg === other.co2PerPersonCarInKg &&
            this.savingsBusToCar === other.savingsBusToCar &&
            this.co2PerPersonBusInKg === other.co2PerPersonBusInKg
        );
    }

    public toString(): string {
        return `requiredCars = ${this.requiredCars}, co2PerPersonCarInKg = ${this.co2PerPersonCarInKg}, savingsBusToCar = ${this.savingsBusToCar}, co2PerPersonBusInKg = ${this.co2PerPersonBusInKg}`;
    }
}
