import { type QueryClient } from '@tanstack/react-query';

import callApi from '@/api';
import { fromJsonArray } from '@/entity';
import { TripCompanySearchForm } from '@/entity/trip/company/TripCompanySearchForm';

export async function getTripCompaniesByName(companyName: string) {
    const params = { companyName };

    const { data } = await callApi(`public/fetchTripCompaniesByNameLike`, { params });

    return fromJsonArray(TripCompanySearchForm, data);
}

export const queryTripCompaniesByName = async (queryClient: QueryClient, companyName: string) =>
    queryClient.ensureQueryData({
        queryKey: ['trip-companies-by-name', companyName],
        queryFn: async () => getTripCompaniesByName(companyName),
    });
