import { createContext, useContext } from 'react';

import { type BookingJourney } from '@/entity/journey/BookingJourney';
import { type TripCompany } from '@/entity/trip/company/TripCompany';

interface IJourneyPlanningContext {
    journey: BookingJourney | null;
    appendTripCompany: (company: TripCompany) => Promise<void>;
}

export const JourneyPlanningContext = createContext<IJourneyPlanningContext>({
    journey: null,
    async appendTripCompany() {},
});

export function useJourneyPlanning() {
    const context = useContext(JourneyPlanningContext);

    return context;
}
