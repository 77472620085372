import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';

import { SuspenseLoader } from '@/components/elements/loader/suspense-loader';
import { AppProgressProvider } from '@/core/app-progress/AppProgressProvider';
import { AppErrorBoundary } from '@/core/AppErrorBoundary';
import { AuthProvider } from '@/core/auth/AuthenticationProvider';
import { CookieBannerProvider } from '@/core/cookie-banner/CookieBannerProvider';
import { DevTools } from '@/core/DevTools';
import { GoogleAnalyticsProvider } from '@/core/google/GoogleAnalytics';
import { GoogleProvider } from '@/core/google/GoogleProvider';
import { InitializeLocalization } from '@/core/localization/InitializeLocalization';
import { LocalizationProviderSetup } from '@/core/localization/LocalizationProviderSetup';
import { MainCompanyDataProvider } from '@/core/main-company-data/MainCompanyDataProvider';
import { ModalProvider } from '@/core/modal/ModalProvider';
import { QueryErrorHandler } from '@/core/query/QueryErrorHandler';
import { SnackProvider } from '@/core/snack/SnackProvider';
import { AppThemeProvider } from '@/core/theme/AppThemeProvider';
import { type AppRouterCtx } from '@/main/AppRouterCtx';

export const Root = () => {
    return (
        <AppErrorBoundary>
            <SuspenseLoader>
                <AuthProvider>
                    <LocalizationProviderSetup>
                        <AppProgressProvider>
                            <AppThemeProvider>
                                <CookieBannerProvider>
                                    <GoogleProvider>
                                        <GoogleAnalyticsProvider>
                                            <SnackProvider>
                                                <ModalProvider>
                                                    <MainCompanyDataProvider>
                                                        <InitializeLocalization>
                                                            <QueryErrorHandler>
                                                                <Outlet />
                                                                <DevTools />
                                                            </QueryErrorHandler>
                                                        </InitializeLocalization>
                                                    </MainCompanyDataProvider>
                                                </ModalProvider>
                                            </SnackProvider>
                                        </GoogleAnalyticsProvider>
                                    </GoogleProvider>
                                </CookieBannerProvider>
                            </AppThemeProvider>
                        </AppProgressProvider>
                    </LocalizationProviderSetup>
                </AuthProvider>
            </SuspenseLoader>
        </AppErrorBoundary>
    );
};

export const Route = createRootRouteWithContext<AppRouterCtx>()({
    component: Root,
});

export { Route as RootRoute };
