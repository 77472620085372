import { FormControl, FormHelperText, TextField, styled } from '@mui/material';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useEffect, useState, type ChangeEvent, type FC } from 'react';
import { useController } from 'react-hook-form';

import { ErrorMessage } from '@/components/form/error-message';
import { makeFieldIds, type FormFieldProps } from '@/components/form/mod';

type SupportedLanguages = 'css' | 'json';

function stringify(language: SupportedLanguages, value: unknown): string {
    if (language === 'json') return JSON.stringify(value, null, 2);
    if (typeof value === 'string') return value;
    return '';
}

function parse(language: SupportedLanguages, value: string): false | string | Record<string, any> {
    if (language === 'json') {
        try {
            const parsed = JSON.parse(value);
            if (typeof parsed !== 'object') return false;
            return parsed;
        } catch {
            return false;
        }
    }

    return value;
}

interface CodeEditorFieldProps extends FormFieldProps {
    readonly language: SupportedLanguages;
}

export const CodeEditorField: FC<CodeEditorFieldProps> = ({
    name,
    id,
    helperText,
    defaultValue,
    language,
    label,
    ...formControlProps
}) => {
    const { field, fieldState } = useController({ name, defaultValue });
    const { onChange, ref, value } = field;
    const { error } = fieldState;

    const isError = Boolean(error);
    const [uid, helperUid] = makeFieldIds(name, id);

    const [code, setCode] = useState(stringify(language, defaultValue));

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setCode(value);

        const parsed = parse(language, value);
        if (parsed === false) return;
        onChange(parsed);
    };

    useEffect(() => {
        setCode(stringify(language, value));
    }, [value, language]);

    return (
        <FormControl fullWidth error={isError} {...formControlProps}>
            <TextField
                multiline
                label={label}
                inputRef={ref}
                id={uid}
                value={code}
                slotProps={{
                    input: {
                        inputComponent: StyledCodeEditor,
                        inputProps: {
                            language,
                            padding: 15,
                        },
                    },
                }}
                onChange={handleChange}
            />
            <FormHelperText id={helperUid}>{isError ? <ErrorMessage error={error} /> : helperText}</FormHelperText>
        </FormControl>
    );
};

// TODO: Maybe move this into the AppTheme
const StyledCodeEditor = styled(CodeEditor)(({ theme }) => ({
    '&.w-tc-editor': {
        '--color-fg-default': theme.vars.palette.text.primary,
        '--color-canvas-subtle': theme.vars.palette.background.paper,
        '--color-prettylights-syntax-comment': theme.vars.palette.text.disabled,
        '--color-prettylights-syntax-entity-tag': theme.vars.palette.success.main,
        '--color-prettylights-syntax-entity': theme.vars.palette.primary.main,
        '--color-prettylights-syntax-sublimelinter-gutter-mark': theme.vars.palette.warning.main,
        '--color-prettylights-syntax-constant': theme.vars.palette.primary.main,
        '--color-prettylights-syntax-string': theme.vars.palette.error.main,
        '--color-prettylights-syntax-keyword': theme.vars.palette.primary.main,
        '--color-prettylights-syntax-markup-bold': theme.vars.palette.error.main,
        ...theme.typography.code,
    },
}));
