import { Grid2, type GridProps } from '@mui/material';
import { isNumber } from 'lodash';
import React from 'react';

interface FormFieldRowProps extends GridProps {
    readonly insertEmptyColumn?: boolean | number;
}

export const FormFieldRow: React.FC<FormFieldRowProps> = ({ children, insertEmptyColumn }) => {
    const emptyColumnLength = isNumber(insertEmptyColumn) ? insertEmptyColumn : insertEmptyColumn ? 1 : 0;
    const gridCount = 12 / (React.Children.toArray(children).length + emptyColumnLength);
    const finiteGridCount = Number.isFinite(gridCount) && gridCount;

    return (
        <>
            {React.Children.map(children, child => (
                <Grid2
                    size={{
                        xs: 12,
                        sm: finiteGridCount,
                    }}>
                    {child}
                </Grid2>
            ))}
            {insertEmptyColumn && (
                <Grid2
                    display={{ xs: 'none', sm: 'block' }}
                    size={{
                        sm: finiteGridCount,
                    }}
                />
            )}
        </>
    );
};
