import { Card } from '@mui/material';
import { useCallback } from 'react';
import ga4 from 'react-ga4';

import { useTripCompanies } from '../hooks/use-trip-companies';

import { TripCompanyDetails } from './trip-company-details';
import { TripCompanyDialog } from './trip-company-dialog';

import { useLayout } from '@/components/page/layout/mod';
import { useJourneyPlanning } from '@/core/journey-planning/mod';
import { type TripCompany } from '@/entity/trip/company/TripCompany';
import { useDisclosure } from '@/hooks/useDisclosure';

interface TripCompanyCardProps {
    readonly company: TripCompany;
}

export const TripCompanyCard: React.FC<TripCompanyCardProps> = ({ company }) => {
    const { journey, appendTripCompany } = useJourneyPlanning();
    const { setHoveredCompany } = useTripCompanies();
    const tripCompanyDialog = useDisclosure();
    const { isDesktop } = useLayout();

    const handleMouseOver = useCallback(() => setHoveredCompany(company.id), [company.id, setHoveredCompany]);
    const handleMouseLeave = useCallback(() => setHoveredCompany(0), [setHoveredCompany]);
    const handleClick = () => {
        if (journey) void appendTripCompany(company);
        else tripCompanyDialog.open();
    };
    const handleDetails = () => ga4.event('trip-company', { event_category: 'open-details-from-results' });

    return (
        <>
            <Card
                key={company.id}
                sx={{
                    scale: '1.01',
                    cursor: 'pointer',
                    transition: theme =>
                        theme.transitions.create('transform', {
                            easing: theme.transitions.easing.easeInOut,
                            duration: theme.transitions.duration.standard,
                        }),

                    '&:hover': {
                        boxShadow: theme => theme.shadows[5],
                        transform: 'scale(1.03)',
                    },

                    '.cta-btn': {
                        opacity: isDesktop ? 0 : 1,
                        transition: theme =>
                            theme.transitions.create('opacity', {
                                easing: theme.transitions.easing.easeInOut,
                                duration: theme.transitions.duration.standard,
                            }),
                    },

                    '&:hover .cta-btn': {
                        opacity: 1,
                    },
                }}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}>
                <TripCompanyDetails company={company} onClick={handleClick} onDetails={handleDetails} />
            </Card>
            <TripCompanyDialog company={company} {...tripCompanyDialog} />
        </>
    );
};
