import { FormControl, FormHelperText, type InputBaseComponentProps, InputLabel } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';

import { ErrorMessage } from '@/components/form/error-message';
import { FormControlInput } from '@/components/form/form-control-input';
import { type FormFieldProps, makeFieldIds } from '@/components/form/mod';

interface TextFieldProps extends FormFieldProps {
    readonly type?: 'text' | 'password' | 'hidden';
    readonly multiline?: boolean;
    readonly minRows?: number;
    readonly maxRows?: number;
    readonly rows?: number;
    readonly endAdornment?: React.ReactNode;
    readonly placeholder?: string;
    readonly handleBlur?: () => void;
    readonly inputProps?: InputBaseComponentProps;
}

export const TextField: React.FC<TextFieldProps> = ({
    name,
    id,
    label,
    helperText,
    type,
    multiline,
    minRows,
    maxRows,
    rows,
    endAdornment,
    placeholder,
    handleBlur,
    fieldRef,
    inputProps,
    ...formControlProps
}) => {
    const { field, fieldState } = useController({ name, defaultValue: '' });
    const { onChange, onBlur, value, ref } = field;
    const { error } = fieldState;

    const isError = Boolean(error);
    const [uid, helperUid] = makeFieldIds(name, id);

    return (
        <FormControl fullWidth error={isError} {...formControlProps}>
            {type !== 'hidden' && <InputLabel htmlFor={uid}>{label}</InputLabel>}

            <FormControlInput
                ref={fieldRef}
                placeholder={placeholder}
                value={value}
                id={uid}
                minRows={minRows}
                maxRows={maxRows}
                rows={rows}
                type={type}
                aria-describedby={helperUid}
                inputRef={ref}
                name={name}
                label={label}
                multiline={multiline}
                endAdornment={endAdornment}
                inputProps={inputProps}
                onChange={onChange}
                onBlur={() => {
                    handleBlur?.();
                    onBlur();
                }}
            />
            {type !== 'hidden' && (
                <FormHelperText id={helperUid}>{isError ? <ErrorMessage error={error} /> : helperText}</FormHelperText>
            )}
        </FormControl>
    );
};
