/**
 * Holds all account types
 */
export enum UserRole {
    CUSTOMER = 'CUSTOMER',
    DRIVER = 'DRIVER',
    OPERATOR = 'OPERATOR',
    TRIP_OPERATOR = 'TRIP_OPERATOR',
    DISPATCHER = 'DISPATCHER',
    ADMIN = 'ADMIN',
    SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum StatusType {
    SUCCESS = 'SUCCESS',
    NOTIFY = 'NOTIFY',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

export enum ModuleType {
    ARRIVAL_RETURN_FLAT_RATE_CALCULATION = 'ARRIVAL_RETURN_FLAT_RATE_CALCULATION',
    BUS_DISCOUNT_GROUP = 'BUS_DISCOUNT_GROUP',
    CALCULATION_SKIP_AVAILABILITY_CHECK = 'CALCULATION_SKIP_AVAILABILITY_CHECK',
    CUSTOM_MAIL_SERVER = 'CUSTOM_MAIL_SERVER',
    FURTHER_BASES = 'FURTHER_BASES',
    IMPORT_RATIO_CUSTOMER = 'IMPORT_RATIO_CUSTOMER',
    INTEGRATION_STATISTICS = 'INTEGRATION_STATISTICS',
    INTERFACE_CS_BUS = 'INTERFACE_CS_BUS',
    INTERFACE_DELTAPLAN = 'INTERFACE_DELTAPLAN',
    INTERFACE_KUSCHICK = 'INTERFACE_KUSCHICK',
    INTERFACE_RATIO = 'INTERFACE_RATIO',
    INTERFACE_TURISTA = 'INTERFACE_TURISTA',
    ONLINE_PAYMENT = 'ONLINE_PAYMENT',
}

export enum ReleaseMode {
    RELEASED = 'RELEASED',
    BLOCKED = 'BLOCKED',
}

export enum CustomerGroupType {
    PRIVATE = 'PRIVATE',
    BUSINESS = 'BUSINESS',
    SCHOOL = 'SCHOOL',
    ASSOCIATION = 'ASSOCIATION',
    RESELLER = 'RESELLER',
}

export enum CouponType {
    RELATIVE = 'RELATIVE',
    ABSOLUTE = 'ABSOLUTE',
}

export enum OfferStatus {
    OPEN = 'OPEN',
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
    DELETED = 'DELETED',
}

export enum PaymentOption {
    ONLINE = 'ONLINE',
    INVOICE = 'INVOICE',
    ALL = 'ALL',
}

export enum DiscountPriceType {
    PRIVATE = 'PRIVATE',
    BUSINESS = 'BUSINESS',
    SCHOOL = 'SCHOOL',
    ASSOCIATION = 'ASSOCIATION',
    RESELLER = 'RESELLER',
    SPECIAL = 'SPECIAL',
    RELEASE = 'RELEASE',
}
