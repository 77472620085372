import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCustomerGroups } from '@/api/public';
import { LocationField } from '@/components/form/fields/location-field';
import { SelectField } from '@/components/form/fields/select-field';
import { TextField } from '@/components/form/fields/text-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';
import { FormFieldsContainer } from '@/components/form/layout/form-fields-container';
import { AssociationFields } from '@/components/form-inputs/association-fields';
import { BusinessInputs } from '@/components/form-inputs/business-inputs';
import { SchoolFields } from '@/components/form-inputs/school-fields';
import { useGoogleGeocoder } from '@/core/google/mod';
import { CustomerGroupType } from '@/entity/common-constants';

interface OrganizationInputsProps {
    readonly withCostCenterAndPoNumber?: boolean;
    readonly withOrganizationRegistration?: boolean;
}

export const OrganizationInputs: React.FC<OrganizationInputsProps> = ({
    withCostCenterAndPoNumber,
    withOrganizationRegistration,
}) => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();
    const { getLocationDetails } = useGoogleGeocoder();

    const customerGroupId = useWatch({
        control,
        name: 'customerGroupId',
    });

    const { customerGroups, customerGroupsForSelect } = useCustomerGroups();

    const currentCustomerGroup = useMemo(
        () => customerGroups.find(customerGroup => customerGroup.id === customerGroupId),
        [customerGroupId, customerGroups],
    );

    const handleChange = async (address: string) => {
        const location = await getLocationDetails({ address });
        setValue('organizationLocation', location);
    };

    const handleChangeCustomerGroup = (id?: number) => {
        const name = customerGroups.find(customerGroup => customerGroup.id === id)?.name;
        setValue('customerGroupName', name);
        setValue('organizationName', '');
        setValue('organizationLocation', null);
    };

    const renderOrganizationInputs = () => {
        switch (currentCustomerGroup?.type) {
            case CustomerGroupType.BUSINESS: {
                return (
                    <>
                        <NameAndLocationFields />
                        <FormFieldRow insertEmptyColumn>
                            <TextField name="organizationUID" label={t('account.organization_data.uid')} />
                        </FormFieldRow>
                        {withCostCenterAndPoNumber && <BusinessInputs />}
                    </>
                );
            }
            case CustomerGroupType.RESELLER: {
                return (
                    <>
                        <NameAndLocationFields />
                        <FormFieldRow insertEmptyColumn>
                            <TextField name="organizationUID" label={t('account.organization_data.uid')} />
                        </FormFieldRow>
                    </>
                );
            }

            case CustomerGroupType.SCHOOL: {
                return (
                    <SchoolFields withOrganizationRegistration={withOrganizationRegistration} onChange={handleChange} />
                );
            }

            case CustomerGroupType.ASSOCIATION: {
                return (
                    <AssociationFields
                        withOrganizationRegistration={withOrganizationRegistration}
                        onChange={handleChange}
                    />
                );
            }

            default: {
                return null;
            }
        }
    };

    return (
        <FormFieldsContainer containerLabel={t('customer_group')}>
            <TextField type="hidden" name="customerGroupName" />

            <FormFieldRow>
                <SelectField
                    required
                    name="customerGroupId"
                    label={t('customer_group')}
                    options={customerGroupsForSelect}
                    data-cy="customerGroup"
                    onChangeCustomerGroup={handleChangeCustomerGroup}
                />
            </FormFieldRow>

            {renderOrganizationInputs()}
        </FormFieldsContainer>
    );
};

const NameAndLocationFields = () => {
    const { t } = useTranslation();

    return (
        <FormFieldRow>
            <TextField required name="organizationName" label={t('account.organization_data.organization_name')} />

            <LocationField
                required
                fullWidth
                label={t('account.organization_data.organization_location')}
                name="organizationLocation"
            />
        </FormFieldRow>
    );
};
