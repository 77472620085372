import { mergeRegister } from '@lexical/utils';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethroughOutlined';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import {
    $getSelection,
    $isRangeSelection,
    FORMAT_TEXT_COMMAND,
    type LexicalEditor,
    SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { type FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToolbarButton } from './toolbar-button';
import { ToolbarGroup } from './toolbar-group';

const LowPriority = 1;

interface TextFormatButtonsProps {
    readonly editor: LexicalEditor;
}

export const TextFormatButtons: FC<TextFormatButtonsProps> = ({ editor }) => {
    const { t } = useTranslation();

    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));
        }
    }, []);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, _newEditor) => {
                    $updateToolbar();
                    return false;
                },
                LowPriority,
            ),
        );
    }, [editor, $updateToolbar]);

    return (
        <ToolbarGroup>
            <ToolbarButton
                hideLabel
                selected={isBold}
                label={t('richtexteditor.format.text.bold.label')}
                icon={<FormatBoldIcon />}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
            />
            <ToolbarButton
                hideLabel
                selected={isItalic}
                label={t('richtexteditor.format.text.italic.label')}
                icon={<FormatItalicIcon />}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
            />
            <ToolbarButton
                hideLabel
                selected={isUnderline}
                label={t('richtexteditor.format.text.underline.label')}
                icon={<FormatUnderlinedIcon />}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                }}
            />
            <ToolbarButton
                hideLabel
                selected={isStrikethrough}
                label={t('richtexteditor.format.text.strikethrough.label')}
                icon={<FormatStrikethroughIcon />}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
                }}
            />
        </ToolbarGroup>
    );
};
