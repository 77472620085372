import { Box, type BoxProps, SvgIcon } from '@mui/material';
import { type FC, forwardRef } from 'react';

import LeafSVG from '@/assets/icons/co2/leaf_icon.svg?react';

export const LeafIcon: FC<BoxProps> = forwardRef((props, ref) => (
    <Box ref={ref} {...props} display="flex" alignItems="center">
        <SvgIcon
            inheritViewBox
            component={LeafSVG}
            sx={{
                width: 18,
                height: 18,
                '& path': {
                    fill(theme) {
                        return theme.vars.palette.eco.main;
                    },
                },
            }}
        />
    </Box>
));
