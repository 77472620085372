import { DateTime } from 'luxon';

import { TOP_EQUIPMENTS, TOP_EQUIPMENTS_EW } from '@/components/bus/mod';
import { Entity } from '@/entity/A_Entity';
import { Document } from '@/entity/basic/Document';
import { Location } from '@/entity/basic/Location';
import { BusBrand } from '@/entity/bus/brand/BusBrand';
import { BusCategory } from '@/entity/bus/category/BusCategory';
import { BusEngine } from '@/entity/bus/engine/BusEngine';
import { Equipment } from '@/entity/bus/equipment/Equipment';
import { BookingCalculation } from '@/entity/calculation/BookingCalculation';
import { type ReleaseMode } from '@/entity/common-constants';
import { BookingBusCompany } from '@/entity/company/bus/BookingBusCompany';
import { fromJsonArray, fromJsonArrayWith } from '@/entity/index';
import { joinNotBlankToString } from '@/utils/helpers';

export class BookingBus extends Entity {
    public plateNumber: string;

    public name: string;

    public avatar?: Document;

    public type: string;

    public registrationDate: DateTime;

    public stars: number;

    public seatsFacingFront: number;

    public seatsFacingBack: number;

    public seatsForGuides: number;

    public homeBase: Location;

    public releaseMode: ReleaseMode;

    public calculatedRating: number;

    public active: boolean;

    public virtual: boolean;

    public description: string;

    public company: BookingBusCompany;

    public calculation: BookingCalculation;

    public brand: BusBrand;

    public engine: BusEngine;

    public documents: Document[];

    public equipments: Equipment[];

    public category?: BusCategory;

    constructor(json: Record<string, any>) {
        super(json);
        this.plateNumber = json.plateNumber;
        this.name = json.name;
        this.avatar = json.avatar ? Document.fromJson(json.avatar) : undefined;
        this.type = json.type;
        this.registrationDate = DateTime.fromISO(json.registrationDate);
        this.stars = json.stars;
        this.seatsFacingFront = json.seatsFacingFront;
        this.seatsFacingBack = json.seatsFacingBack;
        this.seatsForGuides = json.seatsForGuides;
        this.homeBase = Location.fromJson(json.homeBase);
        this.releaseMode = json.releaseMode;
        this.calculatedRating = json.calculatedRating;
        this.active = json.active;
        this.virtual = json.virtual;
        this.description = json.description;
        this.company = new BookingBusCompany(json.company);
        this.calculation = new BookingCalculation(json.calculation);
        this.brand = new BusBrand(json.brand);
        this.engine = new BusEngine(json.engine);
        this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
        this.equipments = fromJsonArray(Equipment, json.equipments);
        this.category = json.category ? new BusCategory(json.category) : undefined;
    }

    public toString(): string {
        return `Bus: ${this.id}`;
    }

    public getTopEquipments(isEmileWeber: boolean) {
        const topEquipments = isEmileWeber ? TOP_EQUIPMENTS_EW : TOP_EQUIPMENTS;
        return this.equipments.filter(eq => topEquipments.find(teq => teq.id === eq.id));
    }

    public getDisplaySeats(): string {
        return `${this.seatsFacingFront + this.seatsFacingBack} + ${this.seatsForGuides}`;
    }

    // If bus type starts with brand name, don't display the brand name twice.
    public getBrandAndType(): string {
        return this.type.startsWith(this.brand.name)
            ? this.type
            : joinNotBlankToString(' ', this.brand.name, this.type);
    }

    public getTotalSeats(): number {
        return this.seatsFacingBack + this.seatsFacingFront + this.seatsForGuides;
    }

    public getImageDocuments(): Document[] {
        return [this.avatar, ...this.documents].filter((x): x is Document => x != null);
    }

    public getMainImage(): Document | undefined {
        return this.getImageDocuments()[0];
    }
}
