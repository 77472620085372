import { DateTime } from 'luxon';

import { Entity } from '@/entity/A_Entity';
import { ContactData } from '@/entity/basic/ContactData';
import { Gateway } from '@/entity/basic/Gateway';
import { Money } from '@/entity/basic/Money';
import { TimeAndLocation } from '@/entity/basic/TimeAndLocation';
import { BookingBus } from '@/entity/bus/BookingBus';
import { Co2BusCalculation } from '@/entity/co2/Co2BusCalculation';
import { BookingCoupon } from '@/entity/coupon/BookingCoupon';
import { PriceDisplayRow } from '@/entity/events/task/PriceDisplayRow';
import { PriceOption } from '@/entity/events/task/PriceOption';
import { fromJsonArray, fromJsonArrayWith } from '@/entity/index';
import { ReviewBooking } from '@/entity/reviews/ReviewBooking';

export class BookingTask extends Entity {
    public name: string;

    public from: TimeAndLocation;

    public to: TimeAndLocation;

    public notes: string;

    public printNotes: string;

    public taskFrom: TimeAndLocation;

    public taskTo: TimeAndLocation;

    public passengers: number;

    public customerContactData: ContactData;

    public price: Money;

    public reducedPrice: Money;

    public priceOption?: PriceOption;

    public paymentPending: boolean;

    public cancelled: boolean;

    public cancellable: boolean;

    public done: boolean;

    public freeCancellationDate: DateTime;

    public cancellationFreeNow: boolean;

    public received: boolean;

    public intermediates: TimeAndLocation[];

    public review?: ReviewBooking;

    public bus: BookingBus;

    public coupon?: BookingCoupon;

    public paymentGateways: Gateway[];

    public priceDisplayRows: PriceDisplayRow[];

    public isEmileWeberTask: boolean;

    public ratioTransferNumber?: string;

    public ratioBasisVorgangsNummer?: string;

    public ratioCustomerId?: number;

    public co2BusCalculation?: Co2BusCalculation;

    constructor(json: Record<string, any>) {
        super(json);
        this.name = json.name;
        this.from = TimeAndLocation.fromJson(json.from);
        this.to = TimeAndLocation.fromJson(json.to);
        this.notes = json.notes;
        this.printNotes = json.printNotes;
        this.taskFrom = TimeAndLocation.fromJson(json.taskFrom);
        this.taskTo = TimeAndLocation.fromJson(json.taskTo);
        this.passengers = json.passengers;
        this.customerContactData = ContactData.fromJson(json.customerContactData);
        this.price = Money.fromJson(json.price);
        this.reducedPrice = Money.fromJson(json.reducedPrice);
        this.priceOption = json.priceOption ? new PriceOption(json.priceOption) : undefined;
        this.paymentPending = json.paymentPending;
        this.cancelled = json.cancelled;
        this.cancellable = json.cancellable;
        this.done = json.done;
        this.freeCancellationDate = DateTime.fromISO(json.freeCancellationDate);
        this.cancellationFreeNow = json.cancellationFreeNow;
        this.received = json.received;
        this.intermediates = fromJsonArrayWith(TimeAndLocation.fromJson, json.intermediates);
        this.bus = new BookingBus(json.bus);
        this.coupon = json.coupon ? new BookingCoupon(json.coupon) : undefined;
        this.paymentGateways = fromJsonArrayWith(Gateway.fromJson, json.paymentGateways);
        this.review = json.review ? new ReviewBooking(json.review) : undefined;
        this.priceDisplayRows = fromJsonArray(PriceDisplayRow, json.priceDisplayRows);
        this.isEmileWeberTask = json.emileWeberTask;
        this.ratioTransferNumber = json.ratioTransferNumber;
        this.ratioBasisVorgangsNummer = json.ratioBasisVorgangsNummer;
        this.ratioCustomerId = json.ratioCustomerId;
        this.co2BusCalculation = Co2BusCalculation.fromJson(json.co2BusCalculation);
    }

    get reviewable(): boolean {
        return this.taskTo.time < DateTime.now() && !this.reviewed;
    }

    get reviewed(): boolean {
        return this.review != null;
    }
}
