import { type i18n } from 'i18next';
import { useEffect, useState, type FC, type PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';

import { useAppCtx } from '../app-ctx/mod';

import { initI18Next } from '@/config/localization';

export const I18NProvider: FC<PropsWithChildren> = ({ children }) => {
    const [i18nextInstance, setI18nextInstance] = useState<i18n>();
    const { appSettings } = useAppCtx();

    useEffect(() => {
        const init = async () => {
            const i18nextInstance = await initI18Next(appSettings.localization);
            setI18nextInstance(i18nextInstance);
        };
        void init();
    });

    if (i18nextInstance == null) return null;

    return <I18nextProvider i18n={i18nextInstance}>{children}</I18nextProvider>;
};
