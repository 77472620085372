import { TextNode, $createTextNode, type SerializedTextNode } from 'lexical';

import { theme } from '../components/theme';

function wrapElementWith(element: HTMLElement | Text, tag: string, className?: string) {
    const el = document.createElement(tag);
    el.append(element);
    if (className) el.setAttribute('class', className);
    return el;
}

export class CustomTextNode extends TextNode {
    static getType() {
        return 'custom-text';
    }

    static clone(node: CustomTextNode) {
        return new CustomTextNode(node.__text, node.__key);
    }

    static importJSON(serializedNode: SerializedTextNode) {
        const node = $createTextNode(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }

    exportJSON(): SerializedTextNode {
        return {
            ...super.exportJSON(),
            type: CustomTextNode.getType(),
            version: 1,
        };
    }

    exportDOM() {
        let element: HTMLElement | Text = document.createTextNode(this.getTextContent());

        if (this.hasFormat('bold')) {
            element = wrapElementWith(element, 'b', theme.text.bold);
        }
        if (this.hasFormat('italic')) {
            element = wrapElementWith(element, 'i', theme.text.italic);
        }
        if (this.hasFormat('underline')) {
            element = wrapElementWith(element, 'u', theme.text.underline);
        }
        if (this.hasFormat('strikethrough')) {
            element = wrapElementWith(element, 's', theme.text.strikethrough);
        }

        return {
            element,
        };
    }
}
