import { CircularProgress } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useBookingJourney } from '@/api/journey/booking/queries';
import { JourneyPlanningContext } from '@/core/journey-planning/mod';
import { useSnack } from '@/core/snack/mod';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';
import { type TripCompany } from '@/entity/trip/company/TripCompany';
import { useCalculateJourney } from '@/features/journey-planning';
import { SuccessText } from '@/features/trip-companies';

interface JourneyPlanningProviderProps {
    readonly token?: string;
    readonly amendBookingToken?: string;
}

export const JourneyPlanningProvider: React.FC<React.PropsWithChildren<JourneyPlanningProviderProps>> = ({
    token,
    amendBookingToken,
    children,
}) => {
    const calculateJourney = useCalculateJourney();
    const snack = useSnack();
    const { t } = useTranslation();

    // Fetch Journey
    const { data: journey, isError } = useBookingJourney({
        step: BookingStep.ROUTE_PLANNING,
        token,
        amendBookingToken,
    });

    const navigate = useNavigate();

    // Clear token search param if there was an error
    useEffect(() => {
        if (isError) void navigate({ to: '/', search: {} });
    }, [isError, navigate]);

    const appendTripCompany = useCallback(
        async (company: TripCompany) => {
            if (!journey || calculateJourney.isPending) return;

            snack.push(
                t('trip_company.adding_company'),
                'info',
                <CircularProgress sx={{ color: theme => theme.vars.palette.Alert.infoIconColor }} size={18} />,
            );

            const formValues = journey.getFormValues();

            // TODO: BUF-1412 remove hardcoded route index and use the selected route index
            // Add the trip company as the last stop
            formValues.routes[0].stops.push({
                departureDateTime: null,
                arrivalDateTime: null,
                localBus: null,
                location: company.location,
                tripCompany: company.getFormValues(),
            });

            try {
                await calculateJourney.mutateAsync(formValues);

                snack.push(<SuccessText companyName={company.companyName} />, 'success');
            } catch {
                snack.push(t('validations:general.error'), 'error');
            }
        },
        [calculateJourney, journey, snack, t],
    );

    const value = useMemo(
        () => ({
            journey,
            appendTripCompany,
        }),
        [appendTripCompany, journey],
    );

    return <JourneyPlanningContext.Provider value={value}>{children}</JourneyPlanningContext.Provider>;
};
