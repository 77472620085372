/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TripCompaniesRouteImport } from './routes/trip-companies/route'
import { Route as SummaryRouteImport } from './routes/summary/route'
import { Route as SearchRouteImport } from './routes/search/route'
import { Route as RegistrationRouteImport } from './routes/registration/route'
import { Route as PrintRouteImport } from './routes/print/route'
import { Route as PartnerRegistrationRouteImport } from './routes/partner-registration/route'
import { Route as OfferConfirmationRouteImport } from './routes/offer-confirmation/route'
import { Route as LoginRouteImport } from './routes/login/route'
import { Route as EditorRouteImport } from './routes/editor/route'
import { Route as DevRouteImport } from './routes/dev/route'
import { Route as CustomerOfferRouteImport } from './routes/customer-offer/route'
import { Route as ContactDataRouteImport } from './routes/contact-data/route'
import { Route as BookingConfirmationRouteImport } from './routes/booking-confirmation/route'
import { Route as AccountRouteImport } from './routes/account/route'
import { Route as IndexImport } from './routes/index'
import { Route as RegistrationIndexImport } from './routes/registration/index'
import { Route as PartnerRegistrationIndexImport } from './routes/partner-registration/index'
import { Route as LoginIndexImport } from './routes/login/index'
import { Route as EditorIndexImport } from './routes/editor/index'
import { Route as AccountIndexImport } from './routes/account/index'
import { Route as RegistrationConfirmationRouteImport } from './routes/registration/confirmation/route'
import { Route as PrintSummaryRouteImport } from './routes/print/summary/route'
import { Route as PrintOperatorOfferRouteImport } from './routes/print/operator-offer/route'
import { Route as PrintOfferComparisonRouteImport } from './routes/print/offer-comparison/route'
import { Route as PrintBookingConfirmationRouteImport } from './routes/print/booking-confirmation/route'
import { Route as PartnerRegistrationConfirmationRouteImport } from './routes/partner-registration/confirmation/route'
import { Route as LoginPasswordResetRouteImport } from './routes/login/password-reset/route'
import { Route as AccountSchoolRouteImport } from './routes/account/school/route'
import { Route as AccountReferralRouteImport } from './routes/account/referral/route'
import { Route as AccountOffersRouteImport } from './routes/account/offers/route'
import { Route as AccountBookingsRouteImport } from './routes/account/bookings/route'
import { Route as AccountAssociationRouteImport } from './routes/account/association/route'

// Create Virtual Routes

const EditorTranslationsLazyImport = createFileRoute('/editor/translations')()
const EditorThemeRouteLazyImport = createFileRoute('/editor/theme')()
const EditorSettingsRouteLazyImport = createFileRoute('/editor/settings')()
const EditorOverviewRouteLazyImport = createFileRoute('/editor/overview')()
const AccountProfileRouteLazyImport = createFileRoute('/account/profile')()
const AccountJourneysRouteLazyImport = createFileRoute('/account/journeys')()
const AccountContactRouteLazyImport = createFileRoute('/account/contact')()

// Create/Update Routes

const TripCompaniesRouteRoute = TripCompaniesRouteImport.update({
  id: '/trip-companies',
  path: '/trip-companies',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/trip-companies/route.lazy').then((d) => d.Route),
)

const SummaryRouteRoute = SummaryRouteImport.update({
  id: '/summary',
  path: '/summary',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/summary/route.lazy').then((d) => d.Route))

const SearchRouteRoute = SearchRouteImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/search/route.lazy').then((d) => d.Route))

const RegistrationRouteRoute = RegistrationRouteImport.update({
  id: '/registration',
  path: '/registration',
  getParentRoute: () => rootRoute,
} as any)

const PrintRouteRoute = PrintRouteImport.update({
  id: '/print',
  path: '/print',
  getParentRoute: () => rootRoute,
} as any)

const PartnerRegistrationRouteRoute = PartnerRegistrationRouteImport.update({
  id: '/partner-registration',
  path: '/partner-registration',
  getParentRoute: () => rootRoute,
} as any)

const OfferConfirmationRouteRoute = OfferConfirmationRouteImport.update({
  id: '/offer-confirmation',
  path: '/offer-confirmation',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/offer-confirmation/route.lazy').then((d) => d.Route),
)

const LoginRouteRoute = LoginRouteImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const EditorRouteRoute = EditorRouteImport.update({
  id: '/editor',
  path: '/editor',
  getParentRoute: () => rootRoute,
} as any)

const DevRouteRoute = DevRouteImport.update({
  id: '/dev',
  path: '/dev',
  getParentRoute: () => rootRoute,
} as any)

const CustomerOfferRouteRoute = CustomerOfferRouteImport.update({
  id: '/customer-offer',
  path: '/customer-offer',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/customer-offer/route.lazy').then((d) => d.Route),
)

const ContactDataRouteRoute = ContactDataRouteImport.update({
  id: '/contact-data',
  path: '/contact-data',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/contact-data/route.lazy').then((d) => d.Route),
)

const BookingConfirmationRouteRoute = BookingConfirmationRouteImport.update({
  id: '/booking-confirmation',
  path: '/booking-confirmation',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/booking-confirmation/route.lazy').then((d) => d.Route),
)

const AccountRouteRoute = AccountRouteImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const RegistrationIndexRoute = RegistrationIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => RegistrationRouteRoute,
} as any)

const PartnerRegistrationIndexRoute = PartnerRegistrationIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PartnerRegistrationRouteRoute,
} as any)

const LoginIndexRoute = LoginIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LoginRouteRoute,
} as any)

const EditorIndexRoute = EditorIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => EditorRouteRoute,
} as any)

const AccountIndexRoute = AccountIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AccountRouteRoute,
} as any)

const EditorTranslationsLazyRoute = EditorTranslationsLazyImport.update({
  id: '/translations',
  path: '/translations',
  getParentRoute: () => EditorRouteRoute,
} as any).lazy(() =>
  import('./routes/editor/translations/lazy').then((d) => d.Route),
)

const EditorThemeRouteLazyRoute = EditorThemeRouteLazyImport.update({
  id: '/theme',
  path: '/theme',
  getParentRoute: () => EditorRouteRoute,
} as any).lazy(() =>
  import('./routes/editor/theme/route.lazy').then((d) => d.Route),
)

const EditorSettingsRouteLazyRoute = EditorSettingsRouteLazyImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => EditorRouteRoute,
} as any).lazy(() =>
  import('./routes/editor/settings/route.lazy').then((d) => d.Route),
)

const EditorOverviewRouteLazyRoute = EditorOverviewRouteLazyImport.update({
  id: '/overview',
  path: '/overview',
  getParentRoute: () => EditorRouteRoute,
} as any).lazy(() =>
  import('./routes/editor/overview/route.lazy').then((d) => d.Route),
)

const AccountProfileRouteLazyRoute = AccountProfileRouteLazyImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AccountRouteRoute,
} as any).lazy(() =>
  import('./routes/account/profile/route.lazy').then((d) => d.Route),
)

const AccountJourneysRouteLazyRoute = AccountJourneysRouteLazyImport.update({
  id: '/journeys',
  path: '/journeys',
  getParentRoute: () => AccountRouteRoute,
} as any).lazy(() =>
  import('./routes/account/journeys/route.lazy').then((d) => d.Route),
)

const AccountContactRouteLazyRoute = AccountContactRouteLazyImport.update({
  id: '/contact',
  path: '/contact',
  getParentRoute: () => AccountRouteRoute,
} as any).lazy(() =>
  import('./routes/account/contact/route.lazy').then((d) => d.Route),
)

const RegistrationConfirmationRouteRoute =
  RegistrationConfirmationRouteImport.update({
    id: '/confirmation',
    path: '/confirmation',
    getParentRoute: () => RegistrationRouteRoute,
  } as any).lazy(() =>
    import('./routes/registration/confirmation/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PrintSummaryRouteRoute = PrintSummaryRouteImport.update({
  id: '/summary',
  path: '/summary',
  getParentRoute: () => PrintRouteRoute,
} as any).lazy(() =>
  import('./routes/print/summary/route.lazy').then((d) => d.Route),
)

const PrintOperatorOfferRouteRoute = PrintOperatorOfferRouteImport.update({
  id: '/operator-offer',
  path: '/operator-offer',
  getParentRoute: () => PrintRouteRoute,
} as any).lazy(() =>
  import('./routes/print/operator-offer/route.lazy').then((d) => d.Route),
)

const PrintOfferComparisonRouteRoute = PrintOfferComparisonRouteImport.update({
  id: '/offer-comparison',
  path: '/offer-comparison',
  getParentRoute: () => PrintRouteRoute,
} as any).lazy(() =>
  import('./routes/print/offer-comparison/route.lazy').then((d) => d.Route),
)

const PrintBookingConfirmationRouteRoute =
  PrintBookingConfirmationRouteImport.update({
    id: '/booking-confirmation',
    path: '/booking-confirmation',
    getParentRoute: () => PrintRouteRoute,
  } as any).lazy(() =>
    import('./routes/print/booking-confirmation/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PartnerRegistrationConfirmationRouteRoute =
  PartnerRegistrationConfirmationRouteImport.update({
    id: '/confirmation',
    path: '/confirmation',
    getParentRoute: () => PartnerRegistrationRouteRoute,
  } as any).lazy(() =>
    import('./routes/partner-registration/confirmation/route.lazy').then(
      (d) => d.Route,
    ),
  )

const LoginPasswordResetRouteRoute = LoginPasswordResetRouteImport.update({
  id: '/password-reset',
  path: '/password-reset',
  getParentRoute: () => LoginRouteRoute,
} as any).lazy(() =>
  import('./routes/login/password-reset/route.lazy').then((d) => d.Route),
)

const AccountSchoolRouteRoute = AccountSchoolRouteImport.update({
  id: '/school',
  path: '/school',
  getParentRoute: () => AccountRouteRoute,
} as any).lazy(() =>
  import('./routes/account/school/route.lazy').then((d) => d.Route),
)

const AccountReferralRouteRoute = AccountReferralRouteImport.update({
  id: '/referral',
  path: '/referral',
  getParentRoute: () => AccountRouteRoute,
} as any).lazy(() =>
  import('./routes/account/referral/route.lazy').then((d) => d.Route),
)

const AccountOffersRouteRoute = AccountOffersRouteImport.update({
  id: '/offers',
  path: '/offers',
  getParentRoute: () => AccountRouteRoute,
} as any).lazy(() =>
  import('./routes/account/offers/route.lazy').then((d) => d.Route),
)

const AccountBookingsRouteRoute = AccountBookingsRouteImport.update({
  id: '/bookings',
  path: '/bookings',
  getParentRoute: () => AccountRouteRoute,
} as any).lazy(() =>
  import('./routes/account/bookings/route.lazy').then((d) => d.Route),
)

const AccountAssociationRouteRoute = AccountAssociationRouteImport.update({
  id: '/association',
  path: '/association',
  getParentRoute: () => AccountRouteRoute,
} as any).lazy(() =>
  import('./routes/account/association/route.lazy').then((d) => d.Route),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/account': {
      id: '/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AccountRouteImport
      parentRoute: typeof rootRoute
    }
    '/booking-confirmation': {
      id: '/booking-confirmation'
      path: '/booking-confirmation'
      fullPath: '/booking-confirmation'
      preLoaderRoute: typeof BookingConfirmationRouteImport
      parentRoute: typeof rootRoute
    }
    '/contact-data': {
      id: '/contact-data'
      path: '/contact-data'
      fullPath: '/contact-data'
      preLoaderRoute: typeof ContactDataRouteImport
      parentRoute: typeof rootRoute
    }
    '/customer-offer': {
      id: '/customer-offer'
      path: '/customer-offer'
      fullPath: '/customer-offer'
      preLoaderRoute: typeof CustomerOfferRouteImport
      parentRoute: typeof rootRoute
    }
    '/dev': {
      id: '/dev'
      path: '/dev'
      fullPath: '/dev'
      preLoaderRoute: typeof DevRouteImport
      parentRoute: typeof rootRoute
    }
    '/editor': {
      id: '/editor'
      path: '/editor'
      fullPath: '/editor'
      preLoaderRoute: typeof EditorRouteImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginRouteImport
      parentRoute: typeof rootRoute
    }
    '/offer-confirmation': {
      id: '/offer-confirmation'
      path: '/offer-confirmation'
      fullPath: '/offer-confirmation'
      preLoaderRoute: typeof OfferConfirmationRouteImport
      parentRoute: typeof rootRoute
    }
    '/partner-registration': {
      id: '/partner-registration'
      path: '/partner-registration'
      fullPath: '/partner-registration'
      preLoaderRoute: typeof PartnerRegistrationRouteImport
      parentRoute: typeof rootRoute
    }
    '/print': {
      id: '/print'
      path: '/print'
      fullPath: '/print'
      preLoaderRoute: typeof PrintRouteImport
      parentRoute: typeof rootRoute
    }
    '/registration': {
      id: '/registration'
      path: '/registration'
      fullPath: '/registration'
      preLoaderRoute: typeof RegistrationRouteImport
      parentRoute: typeof rootRoute
    }
    '/search': {
      id: '/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchRouteImport
      parentRoute: typeof rootRoute
    }
    '/summary': {
      id: '/summary'
      path: '/summary'
      fullPath: '/summary'
      preLoaderRoute: typeof SummaryRouteImport
      parentRoute: typeof rootRoute
    }
    '/trip-companies': {
      id: '/trip-companies'
      path: '/trip-companies'
      fullPath: '/trip-companies'
      preLoaderRoute: typeof TripCompaniesRouteImport
      parentRoute: typeof rootRoute
    }
    '/account/association': {
      id: '/account/association'
      path: '/association'
      fullPath: '/account/association'
      preLoaderRoute: typeof AccountAssociationRouteImport
      parentRoute: typeof AccountRouteImport
    }
    '/account/bookings': {
      id: '/account/bookings'
      path: '/bookings'
      fullPath: '/account/bookings'
      preLoaderRoute: typeof AccountBookingsRouteImport
      parentRoute: typeof AccountRouteImport
    }
    '/account/offers': {
      id: '/account/offers'
      path: '/offers'
      fullPath: '/account/offers'
      preLoaderRoute: typeof AccountOffersRouteImport
      parentRoute: typeof AccountRouteImport
    }
    '/account/referral': {
      id: '/account/referral'
      path: '/referral'
      fullPath: '/account/referral'
      preLoaderRoute: typeof AccountReferralRouteImport
      parentRoute: typeof AccountRouteImport
    }
    '/account/school': {
      id: '/account/school'
      path: '/school'
      fullPath: '/account/school'
      preLoaderRoute: typeof AccountSchoolRouteImport
      parentRoute: typeof AccountRouteImport
    }
    '/login/password-reset': {
      id: '/login/password-reset'
      path: '/password-reset'
      fullPath: '/login/password-reset'
      preLoaderRoute: typeof LoginPasswordResetRouteImport
      parentRoute: typeof LoginRouteImport
    }
    '/partner-registration/confirmation': {
      id: '/partner-registration/confirmation'
      path: '/confirmation'
      fullPath: '/partner-registration/confirmation'
      preLoaderRoute: typeof PartnerRegistrationConfirmationRouteImport
      parentRoute: typeof PartnerRegistrationRouteImport
    }
    '/print/booking-confirmation': {
      id: '/print/booking-confirmation'
      path: '/booking-confirmation'
      fullPath: '/print/booking-confirmation'
      preLoaderRoute: typeof PrintBookingConfirmationRouteImport
      parentRoute: typeof PrintRouteImport
    }
    '/print/offer-comparison': {
      id: '/print/offer-comparison'
      path: '/offer-comparison'
      fullPath: '/print/offer-comparison'
      preLoaderRoute: typeof PrintOfferComparisonRouteImport
      parentRoute: typeof PrintRouteImport
    }
    '/print/operator-offer': {
      id: '/print/operator-offer'
      path: '/operator-offer'
      fullPath: '/print/operator-offer'
      preLoaderRoute: typeof PrintOperatorOfferRouteImport
      parentRoute: typeof PrintRouteImport
    }
    '/print/summary': {
      id: '/print/summary'
      path: '/summary'
      fullPath: '/print/summary'
      preLoaderRoute: typeof PrintSummaryRouteImport
      parentRoute: typeof PrintRouteImport
    }
    '/registration/confirmation': {
      id: '/registration/confirmation'
      path: '/confirmation'
      fullPath: '/registration/confirmation'
      preLoaderRoute: typeof RegistrationConfirmationRouteImport
      parentRoute: typeof RegistrationRouteImport
    }
    '/account/contact': {
      id: '/account/contact'
      path: '/contact'
      fullPath: '/account/contact'
      preLoaderRoute: typeof AccountContactRouteLazyImport
      parentRoute: typeof AccountRouteImport
    }
    '/account/journeys': {
      id: '/account/journeys'
      path: '/journeys'
      fullPath: '/account/journeys'
      preLoaderRoute: typeof AccountJourneysRouteLazyImport
      parentRoute: typeof AccountRouteImport
    }
    '/account/profile': {
      id: '/account/profile'
      path: '/profile'
      fullPath: '/account/profile'
      preLoaderRoute: typeof AccountProfileRouteLazyImport
      parentRoute: typeof AccountRouteImport
    }
    '/editor/overview': {
      id: '/editor/overview'
      path: '/overview'
      fullPath: '/editor/overview'
      preLoaderRoute: typeof EditorOverviewRouteLazyImport
      parentRoute: typeof EditorRouteImport
    }
    '/editor/settings': {
      id: '/editor/settings'
      path: '/settings'
      fullPath: '/editor/settings'
      preLoaderRoute: typeof EditorSettingsRouteLazyImport
      parentRoute: typeof EditorRouteImport
    }
    '/editor/theme': {
      id: '/editor/theme'
      path: '/theme'
      fullPath: '/editor/theme'
      preLoaderRoute: typeof EditorThemeRouteLazyImport
      parentRoute: typeof EditorRouteImport
    }
    '/editor/translations': {
      id: '/editor/translations'
      path: '/translations'
      fullPath: '/editor/translations'
      preLoaderRoute: typeof EditorTranslationsLazyImport
      parentRoute: typeof EditorRouteImport
    }
    '/account/': {
      id: '/account/'
      path: '/'
      fullPath: '/account/'
      preLoaderRoute: typeof AccountIndexImport
      parentRoute: typeof AccountRouteImport
    }
    '/editor/': {
      id: '/editor/'
      path: '/'
      fullPath: '/editor/'
      preLoaderRoute: typeof EditorIndexImport
      parentRoute: typeof EditorRouteImport
    }
    '/login/': {
      id: '/login/'
      path: '/'
      fullPath: '/login/'
      preLoaderRoute: typeof LoginIndexImport
      parentRoute: typeof LoginRouteImport
    }
    '/partner-registration/': {
      id: '/partner-registration/'
      path: '/'
      fullPath: '/partner-registration/'
      preLoaderRoute: typeof PartnerRegistrationIndexImport
      parentRoute: typeof PartnerRegistrationRouteImport
    }
    '/registration/': {
      id: '/registration/'
      path: '/'
      fullPath: '/registration/'
      preLoaderRoute: typeof RegistrationIndexImport
      parentRoute: typeof RegistrationRouteImport
    }
  }
}

// Create and export the route tree

interface AccountRouteRouteChildren {
  AccountAssociationRouteRoute: typeof AccountAssociationRouteRoute
  AccountBookingsRouteRoute: typeof AccountBookingsRouteRoute
  AccountOffersRouteRoute: typeof AccountOffersRouteRoute
  AccountReferralRouteRoute: typeof AccountReferralRouteRoute
  AccountSchoolRouteRoute: typeof AccountSchoolRouteRoute
  AccountContactRouteLazyRoute: typeof AccountContactRouteLazyRoute
  AccountJourneysRouteLazyRoute: typeof AccountJourneysRouteLazyRoute
  AccountProfileRouteLazyRoute: typeof AccountProfileRouteLazyRoute
  AccountIndexRoute: typeof AccountIndexRoute
}

const AccountRouteRouteChildren: AccountRouteRouteChildren = {
  AccountAssociationRouteRoute: AccountAssociationRouteRoute,
  AccountBookingsRouteRoute: AccountBookingsRouteRoute,
  AccountOffersRouteRoute: AccountOffersRouteRoute,
  AccountReferralRouteRoute: AccountReferralRouteRoute,
  AccountSchoolRouteRoute: AccountSchoolRouteRoute,
  AccountContactRouteLazyRoute: AccountContactRouteLazyRoute,
  AccountJourneysRouteLazyRoute: AccountJourneysRouteLazyRoute,
  AccountProfileRouteLazyRoute: AccountProfileRouteLazyRoute,
  AccountIndexRoute: AccountIndexRoute,
}

const AccountRouteRouteWithChildren = AccountRouteRoute._addFileChildren(
  AccountRouteRouteChildren,
)

interface EditorRouteRouteChildren {
  EditorOverviewRouteLazyRoute: typeof EditorOverviewRouteLazyRoute
  EditorSettingsRouteLazyRoute: typeof EditorSettingsRouteLazyRoute
  EditorThemeRouteLazyRoute: typeof EditorThemeRouteLazyRoute
  EditorTranslationsLazyRoute: typeof EditorTranslationsLazyRoute
  EditorIndexRoute: typeof EditorIndexRoute
}

const EditorRouteRouteChildren: EditorRouteRouteChildren = {
  EditorOverviewRouteLazyRoute: EditorOverviewRouteLazyRoute,
  EditorSettingsRouteLazyRoute: EditorSettingsRouteLazyRoute,
  EditorThemeRouteLazyRoute: EditorThemeRouteLazyRoute,
  EditorTranslationsLazyRoute: EditorTranslationsLazyRoute,
  EditorIndexRoute: EditorIndexRoute,
}

const EditorRouteRouteWithChildren = EditorRouteRoute._addFileChildren(
  EditorRouteRouteChildren,
)

interface LoginRouteRouteChildren {
  LoginPasswordResetRouteRoute: typeof LoginPasswordResetRouteRoute
  LoginIndexRoute: typeof LoginIndexRoute
}

const LoginRouteRouteChildren: LoginRouteRouteChildren = {
  LoginPasswordResetRouteRoute: LoginPasswordResetRouteRoute,
  LoginIndexRoute: LoginIndexRoute,
}

const LoginRouteRouteWithChildren = LoginRouteRoute._addFileChildren(
  LoginRouteRouteChildren,
)

interface PartnerRegistrationRouteRouteChildren {
  PartnerRegistrationConfirmationRouteRoute: typeof PartnerRegistrationConfirmationRouteRoute
  PartnerRegistrationIndexRoute: typeof PartnerRegistrationIndexRoute
}

const PartnerRegistrationRouteRouteChildren: PartnerRegistrationRouteRouteChildren =
  {
    PartnerRegistrationConfirmationRouteRoute:
      PartnerRegistrationConfirmationRouteRoute,
    PartnerRegistrationIndexRoute: PartnerRegistrationIndexRoute,
  }

const PartnerRegistrationRouteRouteWithChildren =
  PartnerRegistrationRouteRoute._addFileChildren(
    PartnerRegistrationRouteRouteChildren,
  )

interface PrintRouteRouteChildren {
  PrintBookingConfirmationRouteRoute: typeof PrintBookingConfirmationRouteRoute
  PrintOfferComparisonRouteRoute: typeof PrintOfferComparisonRouteRoute
  PrintOperatorOfferRouteRoute: typeof PrintOperatorOfferRouteRoute
  PrintSummaryRouteRoute: typeof PrintSummaryRouteRoute
}

const PrintRouteRouteChildren: PrintRouteRouteChildren = {
  PrintBookingConfirmationRouteRoute: PrintBookingConfirmationRouteRoute,
  PrintOfferComparisonRouteRoute: PrintOfferComparisonRouteRoute,
  PrintOperatorOfferRouteRoute: PrintOperatorOfferRouteRoute,
  PrintSummaryRouteRoute: PrintSummaryRouteRoute,
}

const PrintRouteRouteWithChildren = PrintRouteRoute._addFileChildren(
  PrintRouteRouteChildren,
)

interface RegistrationRouteRouteChildren {
  RegistrationConfirmationRouteRoute: typeof RegistrationConfirmationRouteRoute
  RegistrationIndexRoute: typeof RegistrationIndexRoute
}

const RegistrationRouteRouteChildren: RegistrationRouteRouteChildren = {
  RegistrationConfirmationRouteRoute: RegistrationConfirmationRouteRoute,
  RegistrationIndexRoute: RegistrationIndexRoute,
}

const RegistrationRouteRouteWithChildren =
  RegistrationRouteRoute._addFileChildren(RegistrationRouteRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/account': typeof AccountRouteRouteWithChildren
  '/booking-confirmation': typeof BookingConfirmationRouteRoute
  '/contact-data': typeof ContactDataRouteRoute
  '/customer-offer': typeof CustomerOfferRouteRoute
  '/dev': typeof DevRouteRoute
  '/editor': typeof EditorRouteRouteWithChildren
  '/login': typeof LoginRouteRouteWithChildren
  '/offer-confirmation': typeof OfferConfirmationRouteRoute
  '/partner-registration': typeof PartnerRegistrationRouteRouteWithChildren
  '/print': typeof PrintRouteRouteWithChildren
  '/registration': typeof RegistrationRouteRouteWithChildren
  '/search': typeof SearchRouteRoute
  '/summary': typeof SummaryRouteRoute
  '/trip-companies': typeof TripCompaniesRouteRoute
  '/account/association': typeof AccountAssociationRouteRoute
  '/account/bookings': typeof AccountBookingsRouteRoute
  '/account/offers': typeof AccountOffersRouteRoute
  '/account/referral': typeof AccountReferralRouteRoute
  '/account/school': typeof AccountSchoolRouteRoute
  '/login/password-reset': typeof LoginPasswordResetRouteRoute
  '/partner-registration/confirmation': typeof PartnerRegistrationConfirmationRouteRoute
  '/print/booking-confirmation': typeof PrintBookingConfirmationRouteRoute
  '/print/offer-comparison': typeof PrintOfferComparisonRouteRoute
  '/print/operator-offer': typeof PrintOperatorOfferRouteRoute
  '/print/summary': typeof PrintSummaryRouteRoute
  '/registration/confirmation': typeof RegistrationConfirmationRouteRoute
  '/account/contact': typeof AccountContactRouteLazyRoute
  '/account/journeys': typeof AccountJourneysRouteLazyRoute
  '/account/profile': typeof AccountProfileRouteLazyRoute
  '/editor/overview': typeof EditorOverviewRouteLazyRoute
  '/editor/settings': typeof EditorSettingsRouteLazyRoute
  '/editor/theme': typeof EditorThemeRouteLazyRoute
  '/editor/translations': typeof EditorTranslationsLazyRoute
  '/account/': typeof AccountIndexRoute
  '/editor/': typeof EditorIndexRoute
  '/login/': typeof LoginIndexRoute
  '/partner-registration/': typeof PartnerRegistrationIndexRoute
  '/registration/': typeof RegistrationIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/booking-confirmation': typeof BookingConfirmationRouteRoute
  '/contact-data': typeof ContactDataRouteRoute
  '/customer-offer': typeof CustomerOfferRouteRoute
  '/dev': typeof DevRouteRoute
  '/offer-confirmation': typeof OfferConfirmationRouteRoute
  '/print': typeof PrintRouteRouteWithChildren
  '/search': typeof SearchRouteRoute
  '/summary': typeof SummaryRouteRoute
  '/trip-companies': typeof TripCompaniesRouteRoute
  '/account/association': typeof AccountAssociationRouteRoute
  '/account/bookings': typeof AccountBookingsRouteRoute
  '/account/offers': typeof AccountOffersRouteRoute
  '/account/referral': typeof AccountReferralRouteRoute
  '/account/school': typeof AccountSchoolRouteRoute
  '/login/password-reset': typeof LoginPasswordResetRouteRoute
  '/partner-registration/confirmation': typeof PartnerRegistrationConfirmationRouteRoute
  '/print/booking-confirmation': typeof PrintBookingConfirmationRouteRoute
  '/print/offer-comparison': typeof PrintOfferComparisonRouteRoute
  '/print/operator-offer': typeof PrintOperatorOfferRouteRoute
  '/print/summary': typeof PrintSummaryRouteRoute
  '/registration/confirmation': typeof RegistrationConfirmationRouteRoute
  '/account/contact': typeof AccountContactRouteLazyRoute
  '/account/journeys': typeof AccountJourneysRouteLazyRoute
  '/account/profile': typeof AccountProfileRouteLazyRoute
  '/editor/overview': typeof EditorOverviewRouteLazyRoute
  '/editor/settings': typeof EditorSettingsRouteLazyRoute
  '/editor/theme': typeof EditorThemeRouteLazyRoute
  '/editor/translations': typeof EditorTranslationsLazyRoute
  '/account': typeof AccountIndexRoute
  '/editor': typeof EditorIndexRoute
  '/login': typeof LoginIndexRoute
  '/partner-registration': typeof PartnerRegistrationIndexRoute
  '/registration': typeof RegistrationIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/account': typeof AccountRouteRouteWithChildren
  '/booking-confirmation': typeof BookingConfirmationRouteRoute
  '/contact-data': typeof ContactDataRouteRoute
  '/customer-offer': typeof CustomerOfferRouteRoute
  '/dev': typeof DevRouteRoute
  '/editor': typeof EditorRouteRouteWithChildren
  '/login': typeof LoginRouteRouteWithChildren
  '/offer-confirmation': typeof OfferConfirmationRouteRoute
  '/partner-registration': typeof PartnerRegistrationRouteRouteWithChildren
  '/print': typeof PrintRouteRouteWithChildren
  '/registration': typeof RegistrationRouteRouteWithChildren
  '/search': typeof SearchRouteRoute
  '/summary': typeof SummaryRouteRoute
  '/trip-companies': typeof TripCompaniesRouteRoute
  '/account/association': typeof AccountAssociationRouteRoute
  '/account/bookings': typeof AccountBookingsRouteRoute
  '/account/offers': typeof AccountOffersRouteRoute
  '/account/referral': typeof AccountReferralRouteRoute
  '/account/school': typeof AccountSchoolRouteRoute
  '/login/password-reset': typeof LoginPasswordResetRouteRoute
  '/partner-registration/confirmation': typeof PartnerRegistrationConfirmationRouteRoute
  '/print/booking-confirmation': typeof PrintBookingConfirmationRouteRoute
  '/print/offer-comparison': typeof PrintOfferComparisonRouteRoute
  '/print/operator-offer': typeof PrintOperatorOfferRouteRoute
  '/print/summary': typeof PrintSummaryRouteRoute
  '/registration/confirmation': typeof RegistrationConfirmationRouteRoute
  '/account/contact': typeof AccountContactRouteLazyRoute
  '/account/journeys': typeof AccountJourneysRouteLazyRoute
  '/account/profile': typeof AccountProfileRouteLazyRoute
  '/editor/overview': typeof EditorOverviewRouteLazyRoute
  '/editor/settings': typeof EditorSettingsRouteLazyRoute
  '/editor/theme': typeof EditorThemeRouteLazyRoute
  '/editor/translations': typeof EditorTranslationsLazyRoute
  '/account/': typeof AccountIndexRoute
  '/editor/': typeof EditorIndexRoute
  '/login/': typeof LoginIndexRoute
  '/partner-registration/': typeof PartnerRegistrationIndexRoute
  '/registration/': typeof RegistrationIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/account'
    | '/booking-confirmation'
    | '/contact-data'
    | '/customer-offer'
    | '/dev'
    | '/editor'
    | '/login'
    | '/offer-confirmation'
    | '/partner-registration'
    | '/print'
    | '/registration'
    | '/search'
    | '/summary'
    | '/trip-companies'
    | '/account/association'
    | '/account/bookings'
    | '/account/offers'
    | '/account/referral'
    | '/account/school'
    | '/login/password-reset'
    | '/partner-registration/confirmation'
    | '/print/booking-confirmation'
    | '/print/offer-comparison'
    | '/print/operator-offer'
    | '/print/summary'
    | '/registration/confirmation'
    | '/account/contact'
    | '/account/journeys'
    | '/account/profile'
    | '/editor/overview'
    | '/editor/settings'
    | '/editor/theme'
    | '/editor/translations'
    | '/account/'
    | '/editor/'
    | '/login/'
    | '/partner-registration/'
    | '/registration/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/booking-confirmation'
    | '/contact-data'
    | '/customer-offer'
    | '/dev'
    | '/offer-confirmation'
    | '/print'
    | '/search'
    | '/summary'
    | '/trip-companies'
    | '/account/association'
    | '/account/bookings'
    | '/account/offers'
    | '/account/referral'
    | '/account/school'
    | '/login/password-reset'
    | '/partner-registration/confirmation'
    | '/print/booking-confirmation'
    | '/print/offer-comparison'
    | '/print/operator-offer'
    | '/print/summary'
    | '/registration/confirmation'
    | '/account/contact'
    | '/account/journeys'
    | '/account/profile'
    | '/editor/overview'
    | '/editor/settings'
    | '/editor/theme'
    | '/editor/translations'
    | '/account'
    | '/editor'
    | '/login'
    | '/partner-registration'
    | '/registration'
  id:
    | '__root__'
    | '/'
    | '/account'
    | '/booking-confirmation'
    | '/contact-data'
    | '/customer-offer'
    | '/dev'
    | '/editor'
    | '/login'
    | '/offer-confirmation'
    | '/partner-registration'
    | '/print'
    | '/registration'
    | '/search'
    | '/summary'
    | '/trip-companies'
    | '/account/association'
    | '/account/bookings'
    | '/account/offers'
    | '/account/referral'
    | '/account/school'
    | '/login/password-reset'
    | '/partner-registration/confirmation'
    | '/print/booking-confirmation'
    | '/print/offer-comparison'
    | '/print/operator-offer'
    | '/print/summary'
    | '/registration/confirmation'
    | '/account/contact'
    | '/account/journeys'
    | '/account/profile'
    | '/editor/overview'
    | '/editor/settings'
    | '/editor/theme'
    | '/editor/translations'
    | '/account/'
    | '/editor/'
    | '/login/'
    | '/partner-registration/'
    | '/registration/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AccountRouteRoute: typeof AccountRouteRouteWithChildren
  BookingConfirmationRouteRoute: typeof BookingConfirmationRouteRoute
  ContactDataRouteRoute: typeof ContactDataRouteRoute
  CustomerOfferRouteRoute: typeof CustomerOfferRouteRoute
  DevRouteRoute: typeof DevRouteRoute
  EditorRouteRoute: typeof EditorRouteRouteWithChildren
  LoginRouteRoute: typeof LoginRouteRouteWithChildren
  OfferConfirmationRouteRoute: typeof OfferConfirmationRouteRoute
  PartnerRegistrationRouteRoute: typeof PartnerRegistrationRouteRouteWithChildren
  PrintRouteRoute: typeof PrintRouteRouteWithChildren
  RegistrationRouteRoute: typeof RegistrationRouteRouteWithChildren
  SearchRouteRoute: typeof SearchRouteRoute
  SummaryRouteRoute: typeof SummaryRouteRoute
  TripCompaniesRouteRoute: typeof TripCompaniesRouteRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AccountRouteRoute: AccountRouteRouteWithChildren,
  BookingConfirmationRouteRoute: BookingConfirmationRouteRoute,
  ContactDataRouteRoute: ContactDataRouteRoute,
  CustomerOfferRouteRoute: CustomerOfferRouteRoute,
  DevRouteRoute: DevRouteRoute,
  EditorRouteRoute: EditorRouteRouteWithChildren,
  LoginRouteRoute: LoginRouteRouteWithChildren,
  OfferConfirmationRouteRoute: OfferConfirmationRouteRoute,
  PartnerRegistrationRouteRoute: PartnerRegistrationRouteRouteWithChildren,
  PrintRouteRoute: PrintRouteRouteWithChildren,
  RegistrationRouteRoute: RegistrationRouteRouteWithChildren,
  SearchRouteRoute: SearchRouteRoute,
  SummaryRouteRoute: SummaryRouteRoute,
  TripCompaniesRouteRoute: TripCompaniesRouteRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/account",
        "/booking-confirmation",
        "/contact-data",
        "/customer-offer",
        "/dev",
        "/editor",
        "/login",
        "/offer-confirmation",
        "/partner-registration",
        "/print",
        "/registration",
        "/search",
        "/summary",
        "/trip-companies"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/account": {
      "filePath": "account/route.tsx",
      "children": [
        "/account/association",
        "/account/bookings",
        "/account/offers",
        "/account/referral",
        "/account/school",
        "/account/contact",
        "/account/journeys",
        "/account/profile",
        "/account/"
      ]
    },
    "/booking-confirmation": {
      "filePath": "booking-confirmation/route.tsx"
    },
    "/contact-data": {
      "filePath": "contact-data/route.tsx"
    },
    "/customer-offer": {
      "filePath": "customer-offer/route.tsx"
    },
    "/dev": {
      "filePath": "dev/route.tsx"
    },
    "/editor": {
      "filePath": "editor/route.tsx",
      "children": [
        "/editor/overview",
        "/editor/settings",
        "/editor/theme",
        "/editor/translations",
        "/editor/"
      ]
    },
    "/login": {
      "filePath": "login/route.tsx",
      "children": [
        "/login/password-reset",
        "/login/"
      ]
    },
    "/offer-confirmation": {
      "filePath": "offer-confirmation/route.tsx"
    },
    "/partner-registration": {
      "filePath": "partner-registration/route.tsx",
      "children": [
        "/partner-registration/confirmation",
        "/partner-registration/"
      ]
    },
    "/print": {
      "filePath": "print/route.tsx",
      "children": [
        "/print/booking-confirmation",
        "/print/offer-comparison",
        "/print/operator-offer",
        "/print/summary"
      ]
    },
    "/registration": {
      "filePath": "registration/route.tsx",
      "children": [
        "/registration/confirmation",
        "/registration/"
      ]
    },
    "/search": {
      "filePath": "search/route.tsx"
    },
    "/summary": {
      "filePath": "summary/route.tsx"
    },
    "/trip-companies": {
      "filePath": "trip-companies/route.tsx"
    },
    "/account/association": {
      "filePath": "account/association/route.tsx",
      "parent": "/account"
    },
    "/account/bookings": {
      "filePath": "account/bookings/route.tsx",
      "parent": "/account"
    },
    "/account/offers": {
      "filePath": "account/offers/route.tsx",
      "parent": "/account"
    },
    "/account/referral": {
      "filePath": "account/referral/route.tsx",
      "parent": "/account"
    },
    "/account/school": {
      "filePath": "account/school/route.tsx",
      "parent": "/account"
    },
    "/login/password-reset": {
      "filePath": "login/password-reset/route.tsx",
      "parent": "/login"
    },
    "/partner-registration/confirmation": {
      "filePath": "partner-registration/confirmation/route.tsx",
      "parent": "/partner-registration"
    },
    "/print/booking-confirmation": {
      "filePath": "print/booking-confirmation/route.tsx",
      "parent": "/print"
    },
    "/print/offer-comparison": {
      "filePath": "print/offer-comparison/route.tsx",
      "parent": "/print"
    },
    "/print/operator-offer": {
      "filePath": "print/operator-offer/route.tsx",
      "parent": "/print"
    },
    "/print/summary": {
      "filePath": "print/summary/route.tsx",
      "parent": "/print"
    },
    "/registration/confirmation": {
      "filePath": "registration/confirmation/route.tsx",
      "parent": "/registration"
    },
    "/account/contact": {
      "filePath": "account/contact/route.lazy.tsx",
      "parent": "/account"
    },
    "/account/journeys": {
      "filePath": "account/journeys/route.lazy.tsx",
      "parent": "/account"
    },
    "/account/profile": {
      "filePath": "account/profile/route.lazy.tsx",
      "parent": "/account"
    },
    "/editor/overview": {
      "filePath": "editor/overview/route.lazy.tsx",
      "parent": "/editor"
    },
    "/editor/settings": {
      "filePath": "editor/settings/route.lazy.tsx",
      "parent": "/editor"
    },
    "/editor/theme": {
      "filePath": "editor/theme/route.lazy.tsx",
      "parent": "/editor"
    },
    "/editor/translations": {
      "filePath": "editor/translations/lazy.tsx",
      "parent": "/editor"
    },
    "/account/": {
      "filePath": "account/index.tsx",
      "parent": "/account"
    },
    "/editor/": {
      "filePath": "editor/index.tsx",
      "parent": "/editor"
    },
    "/login/": {
      "filePath": "login/index.tsx",
      "parent": "/login"
    },
    "/partner-registration/": {
      "filePath": "partner-registration/index.tsx",
      "parent": "/partner-registration"
    },
    "/registration/": {
      "filePath": "registration/index.tsx",
      "parent": "/registration"
    }
  }
}
ROUTE_MANIFEST_END */
