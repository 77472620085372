import LanguageIcon from '@mui/icons-material/Language';
import {
    Button,
    DialogActions,
    DialogContent,
    Grid2,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import cookieSvg from '@/assets/icons/cookie.svg';
import { Image } from '@/components/elements/image/image';
import { supportedLanguages, type Language } from '@/config/localization';
import { COOKIE_CONSENT_VALUES, useCookieBanner } from '@/core/cookie-banner/mod';
import { useLocalization } from '@/core/localization/mod';

interface CookieBannerProps {
    readonly onClose: () => void;
    readonly onChangeView: () => void;
}

export const CookieBanner: React.FC<CookieBannerProps> = ({ onClose, onChangeView }) => {
    const { setCookieConsent } = useCookieBanner();
    const { setLanguage, language } = useLocalization();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClickLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseLanguage = () => {
        setAnchorEl(null);
    };
    const handleDecline = () => {
        setCookieConsent(['necessary']);
        onClose();
    };
    const handleAcceptAll = () => {
        setCookieConsent(COOKIE_CONSENT_VALUES);
        onClose();
    };

    const lngLabel = (lng: Language): string => {
        const parts = [t(`localization:language.${lng}`)];

        if (lng !== 'cimode' && lng !== language) {
            const native = t(`localization:language.${lng}`, { lng });
            parts.push(`(${native})`);
        }

        return parts.join(' ');
    };

    return (
        <>
            <Grid2 container>
                <Grid2 size="grow" />
                <Grid2 display="flex" justifyContent="center" size="grow">
                    <Image width={60} src={cookieSvg} />
                </Grid2>

                <Grid2 display="flex" justifyContent="flex-end" size="grow">
                    <IconButton onClick={handleClickLanguage}>
                        <LanguageIcon />
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        sx={{
                            zIndex(theme) {
                                return theme.vars.zIndex.tooltip;
                            },
                        }}
                        onClose={handleCloseLanguage}>
                        {supportedLanguages().map(lng => (
                            <MenuItem
                                key={lng}
                                value={lng}
                                onClick={() => {
                                    setLanguage(lng);
                                    handleCloseLanguage();
                                }}>
                                {lngLabel(lng)}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid2>
            </Grid2>
            <DialogContent>
                <Typography component="div">
                    <Trans
                        i18nKey="cookies.content"
                        components={{
                            anchor: <Link href="https://www.busfinder.com/datenschutzinformation" target="_blank" />,
                        }}
                    />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button fullWidth variant="outlined" color="error" onClick={handleDecline}>
                    {t('cookies.decline')}
                </Button>
                <Button fullWidth variant="outlined" onClick={onChangeView}>
                    {t('cookies.customize')}
                </Button>
                <Button fullWidth variant="contained" onClick={handleAcceptAll}>
                    {t('cookies.accept')}
                </Button>
            </DialogActions>
        </>
    );
};
