import { ContactDataAddress } from '@/entity/basic/ContactDataAddress';

export class Association {
    public id: number;

    public associationName: string;

    public contactData: ContactDataAddress;

    constructor(json: Record<string, any>) {
        this.id = json.id;
        this.associationName = json.associationName;
        this.contactData = ContactDataAddress.fromJson(json.contactData);
    }
}
