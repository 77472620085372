import React, { useCallback, useMemo, useState } from 'react';

import { JourneyConsiderationsModal } from '@/components/modals/JourneyConsiderationsModal';
import SaveJourneyModal from '@/components/modals/SaveJourneyModal';
import { ModalContext, ModalTypes, type IModalContext, type ModalState } from '@/core/modal/mod';

export const ModalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [modals, setModals] = useState<ModalState[]>([]);

    const setModal = useCallback(
        (modalState: ModalState) => {
            setModals([...modals, modalState]);
        },
        [modals],
    );

    const value: IModalContext = useMemo(
        () => ({
            modals,
            setModal,
        }),
        [modals, setModal],
    );

    const renderModal = (modal: ModalState, handleClose: () => void) => {
        if (!modal) return null;

        switch (modal.modalId) {
            case ModalTypes.SAVE_JOURNEY_MODAL: {
                return <SaveJourneyModal {...(modal.modalProps as any)} handleClose={handleClose} />;
            }
            case ModalTypes.JOURNEY_CONSIDERATIONS_MODAL: {
                return <JourneyConsiderationsModal {...(modal.modalProps as any)} handleClose={handleClose} />;
            }
            default: {
                return null;
            }
        }
    };

    const renderModals = () => {
        return modals.map(modal => {
            const handleClose = () => {
                const openModals = modals.filter(m => m.modalId !== modal.modalId);
                setModals(openModals);
            };

            return <React.Fragment key={modal.modalId}>{renderModal(modal, handleClose)}</React.Fragment>;
        });
    };

    return (
        <ModalContext.Provider value={value}>
            {renderModals()}
            {children}
        </ModalContext.Provider>
    );
};
