import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, type FC } from 'react';

interface OnChangePluginProps {
    readonly onChange?: (htmlString: string) => void;
}

export const OnChangePlugin: FC<OnChangePluginProps> = ({ onChange }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!onChange) return;

        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                const htmlString = $generateHtmlFromNodes(editor, null);
                onChange(htmlString);
            });
        });
    }, [editor, onChange]);

    return null;
};
