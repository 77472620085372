import { boolean, type InferType, string } from 'yup';

import { contactDataSchema, customerGroupSchema } from '@/utils/account';

export interface IRegistrationFormValues extends InferType<typeof registrationSchema> {}

export const registrationSchema = customerGroupSchema.shape({
    contactData: contactDataSchema,
    email: string().email().required(),
    password: string().required().min(3).max(30),
    passwordConfirmation: string().required().matchWith('password', { key: 'password.no_match' }),
    agb: boolean().defined().oneOf([true], { key: 'general.required' }),
    referralToken: string().optional(),
    subscribedToNewsletter: boolean().defined(),
});
