import callApi from '@/api';

/*
 * Api call to validate if the association name is already in use
 */
export async function checkAssociationName(associationName: string) {
    return callApi<{ isAvailable: boolean }>(
        `public/associations/checkAssociationName?associationName=${encodeURIComponent(associationName)}`,
    );
}

/*
 * Api call to validate if the school name is already in use
 */
export async function checkSchoolName(schoolName: string) {
    return callApi<{ isAvailable: boolean }>(
        `public/schools/checkSchoolName?schoolName=${encodeURIComponent(schoolName)}`,
    );
}
