import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isTwoDecimal } from '@/components/form/validations';
import { type Currency } from '@/config/localization';
import { Money } from '@/entity/basic/Money';

interface PriceOverrideInputProps {
    readonly conversionWarnCurrency: Currency;
    readonly value: Money;
    readonly onChange: (value: Money) => void;
}

export const PriceOverrideInput: React.FC<PriceOverrideInputProps> = ({ conversionWarnCurrency, value, onChange }) => {
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = event.target.value;
        if (isTwoDecimal(inputText) || inputText === '') onChange(new Money(inputText as any, value.currency));
    };

    return (
        <TextField
            fullWidth
            label={t('operator.price_override.label')}
            id="overwrite-price"
            value={value.amount}
            slotProps={{
                input: {
                    endAdornment: <InputAdornment position="start">{value.currency}</InputAdornment>,
                    startAdornment: conversionWarnCurrency !== value.currency && (
                        <InputAdornment position="start">
                            <Tooltip title={t('operator.currency_hint', { companyCurrency: conversionWarnCurrency })}>
                                <ErrorOutline />
                            </Tooltip>
                        </InputAdornment>
                    ),
                },
            }}
            onChange={handleChange}
        />
    );
};
