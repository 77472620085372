import { type Interpolation, type Theme } from '@mui/material';

export const theme = {
    heading: {
        h1: 'rt-heading-h1',
        h2: 'rt-heading-h2',
        h3: 'rt-heading-h3',
        h4: 'rt-heading-h4',
        h5: 'rt-heading-h5',
    },

    list: {
        listitem: 'rt-listitem',
        nested: {
            listitem: 'rt-nested-listitem',
        },
        ol: 'rt-list-ol',
        ul: 'rt-list-ul',
    },

    paragraph: 'rt-paragraph',
    placeholder: 'rt-placeholder',

    text: {
        bold: 'rt-text-bold',
        italic: 'rt-text-italic',
        underline: 'rt-text-underline',
        strikethrough: 'rt-text-strikethrough',
        underlineStrikethrough: 'rt-text-underline-strikethrough',
    },
};

export const richTextCss: Interpolation<Theme> = theme => ({
    '.rt-text-bold': {
        fontWeight: theme.typography.fontWeightBold,
    },
    '.rt-text-italic': {
        fontStyle: 'italic',
    },
    '.rt-text-underline': {
        textDecoration: 'underline',
    },
    '.rt-text-strikethrough': {
        textDecoration: 'line-through',
    },
    '.rt-text-underline-strikethrough': {
        textDecoration: 'underline line-through',
    },
    '.rt-heading-h1': {
        ...theme.typography.h1,
    },
    '.rt-heading-h2': {
        ...theme.typography.h2,
    },
    '.rt-heading-h3': {
        ...theme.typography.h3,
    },
    '.rt-heading-h4': {
        ...theme.typography.h4,
    },
    '.rt-heading-h5': {
        ...theme.typography.h5,
    },
    '.rt-heading-h6': {
        ...theme.typography.h6,
    },
});
