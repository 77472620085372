import { Grid2, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DiscountTable } from '@/components/bus/price/discount-table';
import { LightTooltip } from '@/components/elements/light-tooltip';
import { useLayout } from '@/components/page/layout/mod';
import { useJourney } from '@/core/journey/mod';
import { useLocalizedFormatters, useMoneyUtils } from '@/core/localization/mod';
import { Money } from '@/entity/basic/Money';
import { PriceDisplayType, type PriceDisplayRow } from '@/entity/events/task/PriceDisplayRow';
import { useBusSelection } from '@/features/bus-selection';

// t('discounts.PRIVATE')
// t('discounts.BUSINESS')
// t('discounts.SCHOOL')
// t('discounts.ASSOCIATION')
// t('discounts.RESELLER')
// t('discounts.SPECIAL')
// t('discounts.RELEASE')
// t('discounts.COUPON')

interface PriceProps {
    readonly priceToShow?: Money;
    readonly seats: number;
    readonly pax: number;
    readonly priceDisplayRows?: PriceDisplayRow[];
    readonly disableDiscounts?: boolean;
    readonly showFromString?: boolean;
    readonly icon?: React.ReactNode;
    readonly selected?: boolean;
}

export const BusPrice: React.FC<PriceProps> = ({
    priceToShow,
    priceDisplayRows,
    showFromString,
    pax,
    seats,
    icon,
    selected,
    disableDiscounts = false,
}) => {
    const { convertToSelectedCurrency } = useMoneyUtils();
    const { formatMoney } = useLocalizedFormatters();
    const { t } = useTranslation();
    const { hidePrices } = useBusSelection();

    if (hidePrices) return <Skeleton variant="rectangular" width={100} />;
    if (priceToShow == null) return null;

    const hasDiscount = priceDisplayRows?.some(row => row.discount && row.discount.amount < 0);

    const fromString = showFromString ? t('bus.from') : null;

    return (
        <Stack>
            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                    cursor: !hasDiscount || disableDiscounts ? 'inherit' : 'pointer',
                }}>
                {icon}
                <BasePrice
                    priceDisplayRows={priceDisplayRows}
                    hasDiscount={hasDiscount}
                    disableDiscounts={disableDiscounts}
                />

                <LightTooltip
                    disableHoverListener={!hasDiscount || disableDiscounts}
                    placement="top-start"
                    title={<DiscountTable priceDisplayRows={priceDisplayRows} />}>
                    <Typography
                        align="right"
                        color={hasDiscount && !disableDiscounts ? 'success.main' : 'inherit'}
                        variant="price">
                        {fromString} {formatMoney(convertToSelectedCurrency(priceToShow))}
                    </Typography>
                </LightTooltip>
            </Stack>

            <PricePerPerson
                priceToShow={priceToShow}
                fromString={fromString}
                seats={seats}
                pax={pax}
                selected={selected}
            />
        </Stack>
    );
};

interface BasePriceProps {
    readonly priceDisplayRows?: PriceDisplayRow[];
    readonly hasDiscount?: boolean;
    readonly disableDiscounts?: boolean;
}

const BasePrice: React.FC<BasePriceProps> = ({ priceDisplayRows, hasDiscount, disableDiscounts }) => {
    const { formatMoney } = useLocalizedFormatters();
    const { convertToSelectedCurrency } = useMoneyUtils();

    const basePrice = priceDisplayRows?.find(row => row.type === PriceDisplayType.BASE)?.price;

    if (!hasDiscount || disableDiscounts || !basePrice) return null;

    return (
        <Grid2>
            <Typography sx={{ textDecorationLine: 'line-through' }} variant="body3">
                {formatMoney(convertToSelectedCurrency(basePrice))}
            </Typography>
        </Grid2>
    );
};

interface PricePerPersonProps {
    readonly priceToShow: Money;
    readonly seats: number;
    readonly pax: number;
    readonly selected?: boolean;
    readonly fromString: string | null;
}

const PricePerPerson = ({ priceToShow, fromString, seats, pax, selected }: PricePerPersonProps) => {
    const { isDesktop } = useLayout();
    const { t } = useTranslation();
    const { convertToSelectedCurrency } = useMoneyUtils();
    const { formatMoney } = useLocalizedFormatters();
    const { filter } = useBusSelection();
    const { routeDetails } = useJourney();

    const isPricePerSeat = filter.sortBy === 'cheapest_by_price_per_seat';
    const pricePerPerson = new Money(priceToShow.amount / pax, priceToShow.currency);

    if (!routeDetails) return null;
    const remainingPax = routeDetails.sufficientPax || selected ? seats : routeDetails.getRemainingPax();
    const pricePerSeat = new Money(priceToShow.amount / Math.min(seats, remainingPax), priceToShow.currency);

    if (!isDesktop) return null;

    const price = isPricePerSeat
        ? t('price_per_seat', {
              pricePerSeat: formatMoney(convertToSelectedCurrency(pricePerSeat)),
          })
        : t('price_per_person', {
              pricePerPerson: formatMoney(convertToSelectedCurrency(pricePerPerson)),
          });

    return (
        <Typography variant="body3" align="right">
            {fromString} {price}
        </Typography>
    );
};
