import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Heading } from '@/components/elements/heading';
import { Tile } from '@/components/page/layout/tile/tile';
import { TilePart } from '@/components/page/layout/tile/tile-part';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

export const RegistrationLeafletTile: React.FC = () => {
    const { t } = useTranslation();
    const sections = [
        {
            title: t('registration.leaflet.your_account.title'),
            details: <SanitizedInnerHTML html={t('registration.leaflet.your_account.details')} />,
        },
        {
            title: t('registration.leaflet.questions.title'),
            details: (
                <Trans
                    i18nKey="registration.leaflet.questions.details"
                    components={{
                        anchor: <Link href="https://www.busfinder.com/kontakt" target="_blank" />,
                    }}
                />
            ),
        },
    ];

    return (
        <Tile leafletBackground>
            <TilePart>
                <Stack spacing={4}>
                    {sections.map(section => (
                        <Box key={section.title}>
                            <Heading color="primary" title={section.title} />
                            <Typography variant="body2">{section.details}</Typography>
                        </Box>
                    ))}
                </Stack>
            </TilePart>
        </Tile>
    );
};
