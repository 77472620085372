import { type CustomerGroupType } from '@/entity/common-constants';

export const CUSTOMER_GROUP_IDS = [1, 2, 3, 4, 5, 6, 7];

export class CustomerGroup {
    public readonly id: number;

    public readonly name: string;

    public readonly type: CustomerGroupType;

    constructor(json: Record<string, any>) {
        this.id = json.id;
        this.name = json.name;
        this.type = json.type;
    }
}

// t('customer_groups.type.BUSINESS')
// t('customer_groups.type.RESELLER')
// t('customer_groups.type.SCHOOL')
// t('customer_groups.type.PRIVATE')
// t('customer_groups.type.ASSOCIATION')
// t('customer_groups.id.1')
// t('customer_groups.id.2')
// t('customer_groups.id.3')
// t('customer_groups.id.4')
// t('customer_groups.id.5')
// t('customer_groups.id.6')
// t('customer_groups.id.7')
