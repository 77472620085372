import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { DEFAULT_EXTRA_FILTER, PAX_FILTER_ELEMENTS } from '../constants';
import { type ExtraFilter, type IFilter } from '../types';

import { type BusType } from '@/assets/icons/bus-types/BusTypeIcon';
import { type ConvertToCurrency } from '@/core/localization/mod';
import { Money } from '@/entity/basic/Money';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

export function getDifference<T extends Record<string, any>>(obj1: T, obj2: T): Partial<T> | null {
    const difference: Partial<T> = {};

    Object.entries(obj1).forEach(([key]) => {
        if (!isEqual(obj1[key], obj2[key])) {
            difference[key as keyof T] = obj2[key];
        }
    });

    return isEmpty(difference) ? null : difference;
}

export function getExtraFiltersFromFilter(filter: IFilter) {
    const extraFilters = { ...DEFAULT_EXTRA_FILTER };

    const isExtraFilterKey = (key: string): key is keyof ExtraFilter => key in extraFilters;

    Object.keys(filter)
        .filter(isExtraFilterKey)
        .forEach(key => {
            extraFilters[key] = filter[key] as any;
        });

    return extraFilters;
}

export const pricePerSeatSort =
    (convertToCurrency: ConvertToCurrency, remainingRoutePax: number) =>
    (a: BookingSearchResult, b: BookingSearchResult) => {
        const divisor = (seats: number) => (remainingRoutePax === 0 ? seats : Math.min(seats, remainingRoutePax));

        /**
         * Calculate price per seat based on bus seats and remaining pax.
         * - If bus seats >= entered pax, use remaining pax for calculation.
         * - If bus seats < entered pax, use bus seats for calculation as multiple buses will be needed.
         * - If additional buses are needed, use the remaining pax for the next bus.
         */
        const divisorA = divisor(a.bus.getTotalSeats());
        const divisorB = divisor(b.bus.getTotalSeats());

        const moneyA = new Money(a.getPriceToShow().amount / divisorA, a.getPriceToShow().currency);
        const moneyB = new Money(b.getPriceToShow().amount / divisorB, b.getPriceToShow().currency);

        const euroA = convertToCurrency(moneyA, 'EUR').amount;
        const euroB = convertToCurrency(moneyB, 'EUR').amount;

        return euroA - euroB;
    };

export const priceSortASC =
    (convertToCurrency: ConvertToCurrency) => (a: BookingSearchResult, b: BookingSearchResult) => {
        const euroA = convertToCurrency(a.getPriceToShow(), 'EUR').amount;
        const euroB = convertToCurrency(b.getPriceToShow(), 'EUR').amount;

        return euroA - euroB;
    };

export const busStarsSort = (a: BookingSearchResult, b: BookingSearchResult) => {
    if (a.bus.calculatedRating < b.bus.calculatedRating) return 1;

    if (a.bus.calculatedRating > b.bus.calculatedRating) return -1;

    return 0;
};

export const busEngineSort = (a: BookingSearchResult, b: BookingSearchResult) => {
    if (!a.bus.engine.rank || !b.bus.engine.rank) return 0;

    if (a.bus.engine.rank < b.bus.engine.rank) return 1;

    if (a.bus.engine.rank > b.bus.engine.rank) return -1;

    return 0;
};

export const busCompanySortASC = (a: BookingSearchResult, b: BookingSearchResult) => {
    return a.bus.company.companyName.localeCompare(b.bus.company.companyName);
};

export const busCompanySortDESC = (a: BookingSearchResult, b: BookingSearchResult) => {
    return b.bus.company.companyName.localeCompare(a.bus.company.companyName);
};

export const busCompanyRatingSortDESC = (a: BookingSearchResult, b: BookingSearchResult) => {
    if (a.bus.company.reviewScores.totalScore < b.bus.company.reviewScores.totalScore) return 1;

    if (a.bus.company.reviewScores.totalScore > b.bus.company.reviewScores.totalScore) return -1;

    return 0;
};

export const filterByRating = (result: BookingSearchResult, filter: IFilter) => {
    const { minCompanyRating } = filter;
    const rating = result.bus.company.reviewScores.totalScore;

    const ratingConditions = [];

    // Very bad
    if (minCompanyRating.includes(1)) ratingConditions.push(rating > 0 && rating < 1.5);

    // Bad
    if (minCompanyRating.includes(2)) ratingConditions.push(rating < 2.5 && rating >= 1.5);

    // Neutral
    if (minCompanyRating.includes(3)) ratingConditions.push(rating < 3.5 && rating >= 2.5);

    // Good
    if (minCompanyRating.includes(4)) ratingConditions.push(rating < 4.5 && rating >= 3.5);

    // Very good
    if (minCompanyRating.includes(5)) ratingConditions.push(rating >= 4.5);

    return ratingConditions.some(condition => condition);
};

export const busTypeByPax = (pax: number): BusType => {
    return PAX_FILTER_ELEMENTS.find(paxElement => paxElement.minValue <= pax && paxElement.maxValue >= pax)!.type;
};
